"use client";

import { useState, useEffect } from "react";

import { CheckCircle, XCircle } from "lucide-react";
import { cva, type VariantProps } from "class-variance-authority";
import { motion, AnimatePresence } from "framer-motion";
import { cn } from "@princess/ui";

const bannerVariants = cva(
  "relative flex items-center gap-2 overflow-hidden p-2 text-sm",
  {
    variants: {
      variant: {
        success:
          "bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-100",
        error: "bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-100",
      },
    },
    defaultVariants: {
      variant: "success",
    },
  },
);

interface FeedbackBannerProps extends VariantProps<typeof bannerVariants> {
  message: string;
  className?: string;
  onClose?: () => void;
}

export function FeedbackBanner({
  message,
  variant = "success",
  onClose,
  className,
}: FeedbackBannerProps) {
  const [isVisible, setIsVisible] = useState(true);
  const [progress, setProgress] = useState(100);

  useEffect(() => {
    if (variant === "success") {
      const timer = setInterval(() => {
        setProgress((oldProgress) => {
          if (oldProgress <= 0) {
            clearInterval(timer);
            setIsVisible(false);
            return 0;
          }
          return oldProgress - 2; // Decrease by 2% every 100ms
        });
      }, 100);

      return () => clearInterval(timer);
    }
  }, [variant]);

  useEffect(() => {
    if (!isVisible && onClose) {
      const timeout = setTimeout(() => {
        onClose();
      }, 300); // Wait for exit animation to complete
      return () => clearTimeout(timeout);
    }
  }, [isVisible, onClose]);

  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -50 }}
          transition={{ duration: 0.3 }}
          className={cn(bannerVariants({ variant }), className)}
        >
          {variant === "success" ? (
            <CheckCircle className="h-4 w-4" />
          ) : (
            <XCircle className="h-4 w-4" />
          )}
          <span>{message}</span>
          {variant === "success" && (
            <motion.div
              className="absolute bottom-0 left-0 h-1 bg-green-500 dark:bg-green-300"
              initial={{ width: "100%" }}
              animate={{ width: `${progress}%` }}
              transition={{ duration: 0.1 }}
            />
          )}
        </motion.div>
      )}
    </AnimatePresence>
  );
}
