import { env } from "@/env.mjs";
import { type ProjectConfig, projectSetting } from "@/projectSetting";

import packageJson from "../package.json";

const isLocal = env.NEXT_PUBLIC_VERCEL_ENV === "local";
const isPreview = env.NEXT_PUBLIC_VERCEL_ENV === "preview";
const isProduction = env.NEXT_PUBLIC_VERCEL_ENV === "production";
const hostname = env.NEXT_PUBLIC_HOSTNAME;

const config =
  projectSetting[hostname]?.project ?? ({} as ProjectConfig["project"]);

export const publicSetting = {
  env: {
    vercelEnv: env.NEXT_PUBLIC_VERCEL_ENV,
    isLocal,
    isPreview,
    isProduction,
  },
  project: {
    siteName: config.siteName,
    projectSlug: config.projectSlug,
    version: packageJson.version,
    hostname,
  },
  features: {},
  services: {},
};
