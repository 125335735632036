export const appLanguages = {
  zh: {
    label: "繁體中文",
    labelShort: "繁中",
  },
  en: {
    label: "English",
    labelShort: "ENG",
  },
} as const;
