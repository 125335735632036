import { Route } from "@/consts";
import { getPublicConfig } from "@/helpers/getPublicConfig";
import { useUserContext } from "@/oldFeatures/auth/hooks";
import { useTranslation } from "next-i18next";
import ChatIcon from "public/assets/icons/chat.svg";
import HomeIcon from "public/assets/icons/home.svg";
import SearchIcon from "public/assets/icons/search.svg";
import VisitIcon from "public/assets/icons/visit.svg";
import { useMemo } from "react";
import { useIsCurrentRoute } from "./useIsCurrentRoute";

const { isProd } = getPublicConfig();

// Can only be used when the user is logged in
export const useMainNavigationItems = () => {
  const { t } = useTranslation("common");
  const { messageCount } = useUserContext();
  const { isCurrentRoute } = useIsCurrentRoute();
  const { user } = useUserContext();

  return useMemo(
    () => [
      {
        title: t("menu.home"),
        icon: HomeIcon,
        href: Route.MemberHome,
        isActive: isCurrentRoute(Route.MemberHome),
      },
      {
        title:
          user?.gender === "F"
            ? t("menu.search_prince")
            : t("menu.search_princess"),
        icon: SearchIcon,
        href: Route.Search,
        isActive: isCurrentRoute(Route.Search),
      },
      {
        title: t("menu.messages"),
        icon: ChatIcon,
        href: Route.Message,
        badge: messageCount > 0 ? `${messageCount}` : undefined,
        isActive: isCurrentRoute(Route.Message),
      },
      {
        title: t("menu.visitors"),
        icon: VisitIcon,
        href: Route.Visit,
        isActive: isCurrentRoute(Route.Visit),
      },
    ],
    [t, isCurrentRoute, messageCount, user],
  );
};
