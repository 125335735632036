import {
  Textarea as ShadcnTextarea,
  type TextareaProps as ShadcnTextareaProps,
} from "./shadcn/textarea";
import { cn } from ".";
import React from "react";
import { getLengthByMethod, type CountingMethod } from "./custom/InputLength";

type TextareaProps = {
  countingMethod?: CountingMethod;
} & ShadcnTextareaProps;

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  (
    { countingMethod = "normal", className, onChange, maxLength, ...props },
    ref,
  ) => {
    return (
      <ShadcnTextarea
        className={cn(
          "focus-visible:border-primary min-h-[60px] bg-transparent text-base text-gray-900 shadow-sm focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-1 focus-visible:ring-transparent dark:text-current",
          className,
        )}
        onChange={(e) => {
          const value = e.target.value;

          if (maxLength && getLengthByMethod[countingMethod](value) > maxLength)
            return;

          onChange?.(e);
        }}
        ref={ref}
        {...props}
      />
    );
  },
);
Textarea.displayName = "Textarea";

export { Textarea, ShadcnTextareaProps as TextareaProps };
