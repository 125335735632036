export const phoneAreaCodes = [
  "+852",
  "+853",
  "+886",
  "+65",
  "+44",
  "+1",
  "+81",
  "+82",
  "+61",
];
