// @todo - remove this & use Route from @princess/common/route.ts
export enum Route {
  Home = "/",
  AboutUs = "/about-us",
  SafetyTips = "/safety-tips",
  CommunityGuidelines = "/community-guidelines",
  SafetyPolicy = "/safety-and-policy",

  MemberHome = "/home",

  Album = "/albums",

  UserProfile = "/user/[userId]",
  UserProfileReferral = "/users/[userId]/profile/[referral]",

  PaymentSetting = "/settings/billing",
  ProfileSetting = "/settings/profile",
  AccountSetting = "/settings/account",

  Friend = "/friend",
  Visit = "/visit",
  Search = "/search",
  // Hot = "/hot",

  Premium = "/premium",
  Recharge = "/recharge",
  Help = "/help",

  Message = "/messages",
  // MessageUser = "/messages?msguid=[msguid]",

  Privacy = "/privacy-policy",
  Tnc = "/terms-of-use",
  Faq = "/faq",
}
