import { Route } from "@/consts";
import { useDomainContext } from "@/helpers/useDomainContext";
import { useUserContext } from "@/oldFeatures/auth/hooks";
import { useInjectedLoggers } from "@/oldFeatures/ga";
import Image from "next/image";
import Link from "next/link";

export const Logo = () => {
  const { user } = useUserContext();
  const { miscLogger } = useInjectedLoggers();

  const { isMillionBB } = useDomainContext();
  return (
    <Link href={user ? Route.MemberHome : Route.Home} passHref prefetch={false}>
      <span className="sr-only">Princess Dating</span>
      <div className="relative h-10 w-[118.68px] sm:h-12 sm:w-[142.4px]">
        {isMillionBB ? (
          <Image
            onClick={() => miscLogger.pressLogo()}
            src="/assets/images/logo_millionbb.png"
            alt="Princess Dating"
            fill
          />
        ) : (
          <Image
            onClick={() => miscLogger.pressLogo()}
            src="/assets/images/logo_princess.png"
            alt="Princess Dating"
            fill
          />
        )}
      </div>
    </Link>
  );
};
