import { getPublicConfig } from "@/helpers/getPublicConfig";
import { useUserContext } from "@/oldFeatures/auth/hooks";
import constate from "constate";
import { GraphQLClient } from "graphql-request";
import { useMemo } from "react";
import {
  TRPC_HEADER_PROJECT_ID,
  TRPC_HEADER_VERSION,
} from "@princess/api/src/constants";
import { publicSetting } from "@/publicSetting";

const { project } = publicSetting;

const {
  princess: { serverUrl },
} = getPublicConfig();

export const [GraphqlClientProvider, useGraphqlClient] = constate(() => {
  const { token: authToken } = useUserContext();

  const headers = useMemo(() => {
    return {
      ...(authToken ? { authorization: authToken } : {}),
      [TRPC_HEADER_PROJECT_ID]: project.projectSlug,
      [TRPC_HEADER_VERSION]: project.version,
    };
  }, [authToken]);

  const nexusGraphqlClient = useMemo(() => {
    return new GraphQLClient(`${serverUrl}/api/graphql`, {
      headers,
    });
  }, [headers]);

  const graphqlClient = useMemo(() => {
    return new GraphQLClient(`${serverUrl}/server`, {
      headers,
    });
  }, [headers]);

  return {
    /**
     * the nexus graphql client is used for the nexus graphql server
     */
    nexusGraphqlClient,
    /**
     * the graphql client is used for the graphql server
     */
    graphqlClient,
  };
});
