import { useUserContext } from "@/oldFeatures/auth/hooks";
import { useInjectedLoggers } from "@/oldFeatures/ga";
import {
  GenderEnum,
  type UserRegisterInput,
  useUserLoginMutation,
  useUserRegisterMutation,
  useUserVerifyMutation,
} from "@princess/graphql-codegen/graphql-hooks";
import { useTranslation } from "next-i18next";
import { useCallback, useMemo } from "react";

export const useEmailAuth = () => {
  const [emailLogin] = useUserLoginMutation();
  const { refetchUser, setToken } = useUserContext();
  const { authLogger } = useInjectedLoggers();
  const { i18n } = useTranslation();
  const [register] = useUserRegisterMutation();
  const [userVerify] = useUserVerifyMutation();

  const handleEmailRegister = useCallback(
    async (
      payload: Required<Pick<UserRegisterInput, "email" | "password">> &
        Pick<UserRegisterInput, "referrer">,
    ) => {
      // register resolver, return user with idtoken
      authLogger.register("email");
      const { data } = await register({
        variables: {
          userRegisterInput: {
            ...payload,
            gender: GenderEnum.M,
            lang: i18n.language,
          },
        },
      });
      if (data?.userRegister?.idToken) {
        setToken(data?.userRegister.idToken);

        await refetchUser();
      }
    },
    [authLogger, i18n.language, refetchUser, register, setToken],
  );

  const handleEmailLogin = useCallback(
    async (payload: { email: string; password: string }) => {
      // login resolver, return idtoken
      authLogger.login("email");
      const { data } = await emailLogin({
        variables: {
          userLoginInput: {
            ...payload,
          },
        },
      });
      if (data?.userLogin?.idToken) {
        setToken(data?.userLogin.idToken);

        // Do not setUser with the result, so as to maintain single source of truth of user object
        await refetchUser();
      }
      return;
    },
    [authLogger, emailLogin, refetchUser, setToken],
  );

  const handleRegisterVerify = useCallback(
    async (payload: { verifyCode: string }) => {
      // verify resolver, return user without idtoken, need refetch user
      authLogger.verify("email");
      await userVerify({
        variables: { userVerifyInput: { verifyCode: payload.verifyCode } },
      });
      await refetchUser();
    },
    [authLogger, refetchUser, userVerify],
  );

  return useMemo(
    () => ({ handleEmailLogin, handleEmailRegister, handleRegisterVerify }),
    [handleEmailLogin, handleEmailRegister, handleRegisterVerify],
  );
};
