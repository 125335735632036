import { useState } from "react";
import { type DropzoneOptions, useDropzone } from "react-dropzone";

type FileObject = {
  file: File;
  url: string;
};

type SingleImageUploadFieldProps = {
  /**
   *
   * @param url the preview url string
   * @returns
   */
  onChange: (file: FileObject | undefined) => void;
  preview: string | undefined;
  classNames?: {
    container?: string;
  };
  placeholderText?: string;
};

export const useImageDropzone = (
  onChange: SingleImageUploadFieldProps["onChange"],
  dropzoneOptions?: Omit<DropzoneOptions, "accept" | "multiple" | "onDrop">,
) => {
  const [file, setFile] = useState<FileObject>();
  const dropzoneState = useDropzone({
    accept: { "image/*": [] },
    multiple: false,
    onDrop: (acceptedFiles) => {
      const newFile = acceptedFiles[0];
      if (newFile) {
        if (file) URL.revokeObjectURL(file.url);
        const newObjectUrl = URL.createObjectURL(newFile);
        const newObject = {
          file: newFile,
          url: newObjectUrl,
        };
        onChange(newObject);
        setFile(newObject);
      } else {
        onChange(undefined);
      }
    },
    ...dropzoneOptions,
  });

  return {
    ...dropzoneState,
  };
};
