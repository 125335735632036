import countries from "@princess/common/location/country.json";
import subdivisions from "@princess/common/location/subdivision.json";
import { useTranslation } from "next-i18next";
import { useCallback, useMemo } from "react";

export const useLocationOptions = () => {
  const { t } = useTranslation(["find", "common"]);

  const countryOptions = useMemo(() => {
    return Object.keys(countries).map((country) => ({
      label: t(`location:country.${country}`),
      value: country,
    }));
  }, [t]);

  const getDistrictOptions = useCallback(
    (country: string) => {
      return Object.keys(subdivisions)
        .filter((subdivision) => subdivision.startsWith(`${country}_`))
        .map((subdivision) => ({
          label: t(`location:subdivision.${subdivision}`),
          value: subdivision,
        }));
    },
    [t],
  );

  const haveDistrict = useCallback(
    (country: string) => {
      return getDistrictOptions(country).length > 0;
    },
    [getDistrictOptions],
  );

  return useMemo(
    () => ({
      countryOptions,
      getDistrictOptions,
      haveDistrict,
    }),
    [countryOptions, getDistrictOptions, haveDistrict],
  );
};
