import { cn } from "@/helpers/cn";
import { type ReactNode } from "react";

type UserHeaderBadgeProps = {
  children: ReactNode;
  className?: string;
};

export const UserHeaderTag = ({
  children,
  className,
}: UserHeaderBadgeProps) => {
  return (
    <div
      className={cn(
        "flex h-7 items-center rounded-full bg-gray-100 px-2 text-xs text-gray-500",
        className,
      )}
    >
      {children}
    </div>
  );
};
