import { OnboardingForm } from "@/features/auth/components/onboarding/OnbordingForm";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@princess/ui/dialog";
import { useUserContext } from "@/oldFeatures/auth/hooks";
import { useTranslation } from "next-i18next";
import { useCallback } from "react";

type OnboardingDialogProps = {
  isOpen: boolean;
  setIsOpen?: (isOpen: boolean) => void;
};
export const OnboardingDialog = ({
  isOpen,
  setIsOpen,
}: OnboardingDialogProps) => {
  const { t } = useTranslation(["user"]);
  const { logout } = useUserContext();

  const handleOpenChange = useCallback(
    (newIsOpen: boolean) => {
      if (!newIsOpen) {
        logout();
      }

      setIsOpen?.(newIsOpen);
    },
    [logout, setIsOpen],
  );

  return (
    <Dialog open={isOpen} onOpenChange={handleOpenChange}>
      <DialogContent
        onInteractOutside={(e) => e.preventDefault()}
        onEscapeKeyDown={(e) => e.preventDefault()}
        className="sm:max-w-lg"
      >
        <DialogHeader>
          <DialogTitle>{t("onboarding_dialog.title")}</DialogTitle>
        </DialogHeader>
        <DialogDescription>
          <OnboardingForm onFormSubmitted={() => setIsOpen?.(false)} />
        </DialogDescription>
      </DialogContent>
    </Dialog>
  );
};
