import React from "react";
import { UserRoleEnum } from "@princess/graphql-codegen/graphql-hooks";
import { useUserContext } from "@/oldFeatures/auth/hooks";
import { Trans, useTranslation } from "react-i18next";
import { Button } from "@princess/ui/button";
import { Text } from "@princess/ui/custom/text";
import CrownOutlineIcon from "public/assets/icons/crown_outline.svg";
import { cn } from "@princess/ui";
import { useRouter } from "next/router";
import { Route } from "@/consts";
import { formatDatetime } from "@/modules/universal/formatter/datetimeFormatter";
import { getPublicConfig } from "@/helpers/getPublicConfig";

const { dateFormat } = getPublicConfig();

type SubscriptionOverviewProps = {
  onActionClick?: () => void;
};
export const SubscriptionOverview = ({
  onActionClick,
}: SubscriptionOverviewProps) => {
  const { user } = useUserContext();
  const { t } = useTranslation();
  const router = useRouter();

  if (!user) return null;

  const isPaidMember = user.role !== UserRoleEnum.Normal;

  return (
    <div
      className={cn(
        "flex w-full items-center justify-between gap-2 rounded-lg px-4 py-[14px]",
        isPaidMember
          ? "bg-[linear-gradient(109deg,#FAD787_1.63%,#FFEBD4_47.63%,#FADA69_98.4%)]"
          : "bg-[linear-gradient(109deg,#D7D7D7_1.63%,#F3F3F3_47.63%,#B9B9B9_98.4%)]",
      )}
    >
      <div className="flex-1 gap-1.5">
        <div className="flex items-center gap-1.5">
          <CrownOutlineIcon
            width={20}
            height={20}
            fill={isPaidMember ? "#854D0E" : "#404040"}
          />
          <Text
            weight="medium"
            className={cn(isPaidMember ? "text-yellow-800" : "text-gray-700")}
          >
            {isPaidMember
              ? t(`user:role.${user.role.toLowerCase()}`)
              : t("payment:subscription_plan.title")}
          </Text>
        </div>
        {isPaidMember ? (
          <Text as="p" size="sm" className="text-left text-[#854D0E]">
            <Trans
              i18nKey="payment:subscription_field.end_date"
              values={{
                endDate: formatDatetime(
                  new Date(
                    parseInt(
                      user.role === UserRoleEnum.Premium
                        ? (user?.premiumEndDt ?? "")
                        : (user?.silverEndDt ?? ""),
                    ),
                  ),
                  { dateFormats: { base: dateFormat } },
                ),
              }}
              components={{
                endDateText: (
                  <Text size="sm" className="whitespace-nowrap text-current" />
                ),
              }}
            />
          </Text>
        ) : (
          <Text size="sm" className={cn("text-gray-500")}>
            {t("payment:subscription_plan.description")}
          </Text>
        )}
      </div>
      <Button
        size="sm"
        className={cn(
          "rounded-full bg-white hover:bg-gray-50",
          isPaidMember ? "text-primary-dark" : "text-gray-900",
        )}
        onClick={() => {
          onActionClick?.();
          void router.push(Route.Premium);
        }}
      >
        {isPaidMember
          ? t("payment:subscription_plan.view_privileges")
          : t("user:frd_req.upgrade_action")}
      </Button>
    </div>
  );
};
