import { QueryClient, useQueryClient } from "@tanstack/react-query";
import React from "react";

const queryClient = new QueryClient();

const context = React.createContext<QueryClient | undefined>(queryClient);

export const useGraphqlReactQueryUtils = () => {
  return {
    queryClient: useQueryClient({ context }),
    context,
  };
};
