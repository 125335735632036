import { UserNotificationItem } from "@/features/notification/components/UserNotificationItem";
import { getPublicConfig } from "@/helpers/getPublicConfig";
import { trpc } from "@/utils/trpc";
import { cn } from "@princess/ui";
import { NoNotificationDisplay } from "@/features/notification/components/NoNotificationDisplay";
import { NotificationList } from "@/modules/features/notification/components/NotificationList";
import { useMarkNotificationAsRead } from "@/features/notification/hooks/useMarkNotificationAsRead";
import { useEffect } from "react";

const {
  features: {
    notification: { popoverItemsPerPage },
  },
} = getPublicConfig();

type RecentNotificationListProps = {
  hasUnreadNotification: boolean;
};
export const RecentNotificationList = ({
  hasUnreadNotification,
}: RecentNotificationListProps) => {
  const {
    data,
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    isSuccess,
  } = trpc.notification.notifications.useInfiniteQuery(
    {
      take: popoverItemsPerPage,
    },
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.length < popoverItemsPerPage) return undefined;

        return { id: lastPage.at(-1)?.id };
      },
    },
  );
  const markNotificationAsReadMutation = useMarkNotificationAsRead();

  useEffect(() => {
    if (isSuccess && hasUnreadNotification) {
      markNotificationAsReadMutation.mutate();
    }
  }, [hasUnreadNotification, isSuccess]);

  const notifications = data?.pages.flat() ?? [];

  return (
    <NotificationList
      notifications={notifications}
      renderNotification={(notification, i) => (
        <UserNotificationItem
          className={cn(
            i !== notifications.length - 1 && "border-b border-b-gray-200 pb-4",
          )}
          notification={notification}
        />
      )}
      isLoading={isLoading}
      isFetchingNextPage={isFetchingNextPage}
      hasNextPage={hasNextPage}
      fetchNextPage={fetchNextPage}
      emptyListDisplay={<NoNotificationDisplay />}
    />
  );
};
