import { Route } from "@/consts/Route";
import { useTranslation } from "next-i18next";
import { useMemo } from "react";

export type PublicNavigationItem = {
  title: string;
  href: string;
  subItems?: never;
};

export type PublicNavigationItemWithSubItems = {
  title: string;
  subItems: PublicNavigationItem[];
};

export const usePublicNavigationItems = (): Array<
  PublicNavigationItem | PublicNavigationItemWithSubItems
> => {
  const { t } = useTranslation();

  return useMemo(
    () => [
      {
        title: t("common:menu.home"),
        href: Route.Home,
      },
      {
        title: t("common:menu.about_us"),
        href: Route.AboutUs,
      },
      {
        title: t("common:menu.safety_short"),
        subItems: [
          {
            title: t("common:menu.community_guidelines"),
            href: Route.CommunityGuidelines,
          },
          {
            title: t("common:menu.safety"),
            href: Route.SafetyTips,
          },
          {
            title: t("common:menu.safety_policy"),
            href: Route.SafetyPolicy,
          },
        ],
      },
      {
        title: t("common:menu.support"),
        href: Route.Faq,
      },
    ],
    [t],
  );
};
