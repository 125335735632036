import { trpc } from "@/utils/trpc";

export const useMarkNotificationAsRead = () => {
  const utils = trpc.useUtils();

  return trpc.notification.markAllAsRead.useMutation({
    onMutate: async () => {
      await utils.notification.unreadNotificationCount.cancel();

      const previousUnreadCount =
        utils.notification.unreadNotificationCount.getData();

      utils.notification.unreadNotificationCount.setData(undefined, 0);

      return {
        previousUnreadCount,
      };
    },
    onError: (_, __, context) => {
      if (!context) return;

      utils.notification.unreadNotificationCount.setData(
        undefined,
        context.previousUnreadCount,
      );
    },
    onSettled: () => {
      utils.notification.unreadNotificationCount.invalidate();
      utils.notification.notifications.invalidate();
    },
  });
};
