import va from "@vercel/analytics";

type PayloadMap = {
  buyCredit: {
    amount: number;
  };

  subscribe: {
    plan: string;
  };

  unsubscribe: {
    plan: string;
  };

  register: {
    gender: string;
  };
};

export const track = <T extends keyof PayloadMap>(
  action: T,
  payload: PayloadMap[T],
) => {
  return va.track(action, payload);
};
