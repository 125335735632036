import { UserHeader } from "@/featuresPrincess/layout/UserHeader";
import { useUserContext } from "@/oldFeatures/auth/hooks";
import { NavContainer } from "@/featuresPrincess/layout/NavContainer";
import { PublicHeader } from "@/features/common/layout/header/PublicHeader";

export const Header = () => {
  const { user, loading } = useUserContext();

  if (loading) {
    return (
      <NavContainer>
        <div className="hidden animate-pulse flex-row space-x-4 md:flex ">
          <div className="h-6 w-16 rounded-full bg-gray-300"></div>
          <div className="h-6 w-16 rounded-full bg-gray-300"></div>
          <div className="h-6 w-16 rounded-full bg-gray-300"></div>
          <div className="h-6 w-24 rounded-full bg-gray-300"></div>
        </div>
        <div className="flex animate-pulse space-x-4 md:hidden">
          <div className="h-6 w-8 rounded-full bg-gray-300"></div>
          <div className="h-6 w-6 rounded-full bg-gray-300"></div>
        </div>
      </NavContainer>
    );
  }

  if (!user) return <PublicHeader />;

  return <UserHeader />;
};
