import { type PublicNavigationItem } from "@/features/common/hooks/usePublicNavigationItems";
import { cn } from "@/helpers/cn";
import { useIsCurrentRoute } from "@/featuresPrincess/common/hooks/useIsCurrentRoute";
import { NavLink } from "@/features/common/layout/header/NavLink";

type NavItemProps = {
  onClick?: () => void;
  className?: string;
} & PublicNavigationItem;

export const NavItem = ({ onClick, className, ...item }: NavItemProps) => {
  const { isCurrentRoute } = useIsCurrentRoute();

  return (
    <NavLink
      href={item.href!}
      className={cn(isCurrentRoute(item.href) && "font-medium", className)}
      onClick={onClick}
    >
      {item.title}
    </NavLink>
  );
};
