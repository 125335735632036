import { getPublicConfig } from "@/helpers/getPublicConfig";
import { notistackRef } from "@/oldFeatures/auth/hooks/customSnackbarContext";
import { ApolloClient, ApolloLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { RetryLink } from "@apollo/client/link/retry";
import { createUploadLink } from "apollo-upload-client";
import { parse } from "cookie";
import { GraphQLError, type OperationDefinitionNode } from "graphql";
import { type AppContext } from "next/app";

const HEADER_VERSION = "X-Version";
const APP_NAME = "X-App-Name";
const { authHeaderKey, version, princess, projectSlug, hostname } =
  getPublicConfig();

const defaultHeaders = {
  [APP_NAME]: hostname === "millionbb" ? "millionbb-web" : "princess-web",
  [HEADER_VERSION]: version,
};

const retryLink = new RetryLink({
  delay: {
    initial: 250,
    max: Infinity,
    jitter: false,
  },
  attempts: {
    max: 5,
    retryIf: (error, operation) => {
      // never try for mutation
      if (
        (operation.query.definitions[0] as OperationDefinitionNode)
          .operation === "mutation"
      )
        return false;
      // Error without code should be unknown error, need to retry
      return !(error instanceof GraphQLError) || !error.extensions?.code;
    },
  },
});

const errorLink = onError((error) => {
  if (typeof window === "undefined") {
    return;
  }
  const { operation, networkError, graphQLErrors } = error;

  if (networkError) {
    // only mutation will show snackbar
    if (
      (operation.query.definitions[0] as OperationDefinitionNode).operation ===
      "mutation"
    )
      notistackRef.current?.enqueueSnackbar(
        <div className="flex flex-col">
          <text className="mb-2 font-bold underline">
            Operation: {operation.operationName}
          </text>
          <text>Network Error: {networkError.message}</text>
          {graphQLErrors && (
            <text>GraphQL Error: {graphQLErrors[0]?.message}</text>
          )}
        </div>,
        { variant: "error" },
      );
  }
});

function createIsomorphLink(appCtx?: AppContext) {
  return ApolloLink.from([
    errorLink,
    retryLink,
    setContext(() => {
      if (typeof window !== "undefined") {
        const authorization = window.localStorage.getItem(authHeaderKey);
        if (authorization) {
          return { headers: { ...defaultHeaders, authorization } };
        }
      } else if (appCtx?.ctx) {
        const parsedCookies = parse(appCtx.ctx?.req?.headers?.cookie ?? "");
        const authorization = parsedCookies[authHeaderKey];
        if (authorization) {
          return { headers: { ...defaultHeaders, authorization } };
        }
      }

      return { headers: defaultHeaders };
    }),
    createUploadLink({
      uri: `${princess.serverUrl}/server`,
      headers: { "Apollo-Require-Preflight": "true" },
    }),
  ]);
}

/**
 * a function to create an apollo client
 * @param appCtx
 * @returns the apollo client
 */
function createApolloClient(appCtx?: AppContext) {
  return new ApolloClient({
    ssrMode: typeof window === "undefined",
    link: createIsomorphLink(appCtx),
    cache: new InMemoryCache(),
    defaultOptions: {
      query: {
        errorPolicy: "all",
      },
      watchQuery: {
        errorPolicy: "all",
      },
    },
    name: projectSlug,
  });
}

export const apolloClient = createApolloClient();
