import { AvatarDisplay } from "@/features/user/components/AvatarDisplay";
import { type UserNotification } from "@princess/api/src/types/notification";
import {
  FriendshipMethodEnum,
  NotificationStatusEnum,
  NotificationTypeEnum,
} from "@princess/db/prisma";
import { parseUserStatus } from "@princess/graphql-codegen/src/helpers/parseGraphqlEnums";
import { Trans, useTranslation } from "next-i18next";
import { Text } from "@princess/ui/custom/text";
import { LinkToUserProfile } from "@/features/user/components/LinkToUserProfile";
import { type PropsWithChildren } from "react";
import { NotificationItemContainer } from "@/modules/features/notification/components/NotificationItemContainer";
import { formatDatetime } from "@/modules/universal/formatter/datetimeFormatter";
import { dateFnsLocaleByLang } from "@/consts/datetime";
import { useAppLanguage } from "@/featuresPrincess/common/hooks/useAppLanguage";

type UserNotificationItemProps = {
  className?: string;
  notification: UserNotification;
};
export const UserNotificationItem = ({
  notification,
  className,
}: UserNotificationItemProps) => {
  const { t } = useTranslation("notification");
  const { sender, createdAt, status, type } = notification;
  const { currentLanguage } = useAppLanguage();

  const messageByNotificationType: Record<NotificationTypeEnum, string> = {
    [NotificationTypeEnum.NEW_FRIEND]: "notification:message.new_friend",
  };

  return (
    <NotificationItemContainer
      classes={{ container: className }}
      unread={status === NotificationStatusEnum.UNREAD}
    >
      <div className="flex items-center gap-3.5">
        <LinkToUserProfile userId={sender.id} className="relative block">
          <div className="absolute inset-0 z-50 rounded-full hover:bg-black/10" />
          <AvatarDisplay
            alt={`avatar-${notification.id}`}
            user={{
              avatar: sender.avatar,
              avatarBanned: sender.avatarBanned,
              status: parseUserStatus(sender.status),
            }}
            shape="roundedFull"
            size="xs"
          />
        </LinkToUserProfile>

        <div className="space-y-1">
          <div className="flex items-center gap-1">
            <Text as="p">
              <Trans
                i18nKey={messageByNotificationType[type]}
                values={{ name: sender.nickname }}
                components={[<UserNameWrapper key={0} userId={sender.id} />]}
              />
            </Text>
            <UserNotificationItemCaption notification={notification} />
          </div>

          <Text className="block text-[0.813rem]" variant="muted" size="xs">
            {formatDatetime(createdAt, {
              ago: true,
              labels: {
                justNow: t("datetime:just_now"),
                yesterday: t("datetime:yesterday"),
              },
              locale: dateFnsLocaleByLang[currentLanguage],
            })}
          </Text>
        </div>
      </div>
    </NotificationItemContainer>
  );
};

type UserNotificationCaptionProps = {
  notification: UserNotification;
};
const UserNotificationItemCaption = ({
  notification,
}: UserNotificationCaptionProps) => {
  const { type, friendshipMethod } = notification;

  if (
    type === NotificationTypeEnum.NEW_FRIEND &&
    friendshipMethod === FriendshipMethodEnum.WINE
  ) {
    return <Text>🌹</Text>;
  }

  return null;
};

type UserNameWrapperProps = PropsWithChildren<{
  userId: number;
}>;
const UserNameWrapper = ({ userId, children }: UserNameWrapperProps) => {
  return (
    <LinkToUserProfile userId={userId}>
      <Text
        className="inline-block max-w-24 truncate align-bottom hover:text-gray-900"
        weight="medium"
      >
        {children}
      </Text>
    </LinkToUserProfile>
  );
};
