import { useRouter } from "next/router";
import { useCallback, useMemo } from "react";

export const useIsCurrentRoute = () => {
  const router = useRouter();

  const isCurrentRoute = useCallback(
    (href?: string) => {
      if (!href) return false;

      return router?.pathname === href;
    },
    [router?.pathname],
  );

  return useMemo(
    () => ({
      isCurrentRoute,
    }),
    [isCurrentRoute],
  );
};
