import {
  type ChangeEventHandler,
  useCallback,
  useState,
  useEffect,
} from "react";
import {
  InputGroup,
  InputControl,
  InputItem,
} from "@/modules/input/extensions/input-extensions";
import { Input } from "@princess/ui/input";
import { Button } from "@princess/ui/button";
import { Text } from "@princess/ui/custom/text";
import { useCountdown } from "usehooks-ts";

type AuthVerificationInputProps = {
  handleSendVerification: () => Promise<void | boolean>;
  handleSendVerificationError: (error: unknown) => void;
  handleSendVerificationSuccess?: () => void;
  sendVerificationButtonDisabled?: boolean;
  resendInterval?: number;
  value: string;
  error: string | null;
  onChange: ChangeEventHandler<HTMLInputElement>;
  renderButtonLabel?: (props: {
    isCountdownRunning: boolean;
    countdown: number;
  }) => string;
  classes?: {
    input?: string;
    button?: string;
  };
};
export const AuthVerificationCodeInput = ({
  value,
  onChange,
  error,
  resendInterval = 60,
  handleSendVerification,
  handleSendVerificationError,
  handleSendVerificationSuccess,
  sendVerificationButtonDisabled = false,
  renderButtonLabel,
  classes = {},
}: AuthVerificationInputProps) => {
  const [isSendingCode, setIsSendingCode] = useState(false);
  const [isVerificationSent, setIsVerificationSent] = useState(false);
  const [countdown, { startCountdown, stopCountdown }] = useCountdown({
    countStart: resendInterval,
    intervalMs: 1000,
  });
  const [isCountdownRunning, setIsCountdownRunning] = useState(false);

  useEffect(() => {
    if (countdown === 0) {
      stopCountdown();
      setIsCountdownRunning(false);
    }
  }, [countdown, stopCountdown]);

  const onSendVerification = useCallback(async () => {
    setIsSendingCode(true);

    try {
      const validEmailAndPassword = await handleSendVerification();
      if (
        typeof validEmailAndPassword === "boolean" &&
        !validEmailAndPassword
      ) {
        setIsSendingCode(false);
        return;
      }
      startCountdown();
      setIsCountdownRunning(true);
      setIsVerificationSent(true);
      handleSendVerificationSuccess?.();
    } catch (err) {
      handleSendVerificationError(err);
    } finally {
      setIsSendingCode(false);
    }
  }, [
    handleSendVerification,
    handleSendVerificationError,
    handleSendVerificationSuccess,
    startCountdown,
  ]);

  return (
    <div>
      <InputGroup>
        <InputControl.Left>
          <Input
            value={value}
            onChange={onChange}
            disabled={!isVerificationSent}
            className={classes.input}
          />
        </InputControl.Left>
        <InputItem.Right>
          <Button
            type="button"
            onClick={onSendVerification}
            disabled={isCountdownRunning || sendVerificationButtonDisabled}
            variant="ghost"
            isLoading={isSendingCode}
            className={classes.button}
          >
            {renderButtonLabel
              ? renderButtonLabel({ isCountdownRunning, countdown })
              : `Send Verification Code` +
                (isCountdownRunning ? `(${countdown})` : "")}
          </Button>
        </InputItem.Right>
      </InputGroup>

      {error && (
        <Text size="sm" className="text-red-500">
          {error}
        </Text>
      )}
    </div>
  );
};
