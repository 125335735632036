import { UserStatusEnum, UserRoleEnum } from "../generated/graphql-react-query";

// All enums are refernced from princess-server repo - src/resolvers/__enum/
// Should remove this logic after int enum is migrated to prisma enum

const userStatusByNumEnum = {
  0: UserStatusEnum.NotVerify,
  1: UserStatusEnum.Verified,
  2: UserStatusEnum.ProfileCompleted,
  7: UserStatusEnum.Deleted,
  9: UserStatusEnum.Banned,
} as const;
export const parseUserStatus = (status: number): UserStatusEnum => {
  return userStatusByNumEnum[status];
};

const userRoleByNumEnum = {
  1: UserRoleEnum.Normal,
  2: UserRoleEnum.Silver,
  3: UserRoleEnum.Premium,
  4: UserRoleEnum.HLvMember,
} as const;
export const parseUserRole = (role: number): UserRoleEnum => {
  return userRoleByNumEnum[role];
};
