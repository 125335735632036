import { getPublicConfig } from "@princess/api/src/helpers/getPublicConfig";
import { createContext, useContext } from "react";

const { isLocal } = getPublicConfig();

type DomainContextState = {
  domain: string;
  websiteName: string;
  isMillionBB: boolean;
};

const DomainContext = createContext({} as DomainContextState);

export const DomainProvider = ({
  hostname,
  children,
}: {
  hostname: string;
  children: React.ReactNode;
}) => {
  const isMillionBB = !isLocal && hostname === "millionbb";
  const domain = isMillionBB ? "millionbb.com" : "princess-dating.com";
  const websiteName = isMillionBB ? "MillionBB" : "Princess Dating";

  return (
    <DomainContext.Provider
      value={{
        domain,
        websiteName,
        isMillionBB,
      }}
    >
      {children}
    </DomainContext.Provider>
  );
};

export const useDomainContext = () => useContext(DomainContext);
