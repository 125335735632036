enum GraphqlEvent {
  INTERNAL_ERROR = "GRAPHQL__INTERNAL_ERROR",
}

export enum CloudinaryEvent {
  UPLOAD_IMAGE_SUCCESS = "CLOUDINARY__UPLOAD_IMAGE_SUCCESS",
  UPLOAD_IMAGE_FAIL = "CLOUDINARY__UPLOAD_IMAGE_FAIL",
}

export enum AuthEvent {
  LOGIN = "AUTH__LOGIN",
  REGISTER = "AUTH__REGISTER",
  RESEND_VERIFY = "AUTH__RESEND_VERIFY",
  VERIFY = "AUTH__VERIFY",
}
export enum UserEvent {
  FAVOURITE = "USER__FAVOURITE",
  REPORT_USER = "USER__REPORT_USER",
  RATE_USER = "USER__RATE_USER",
  FRIEND_REQUEST = "USER__FRIEND_REQUEST",
}

export enum SiteEvent {
  PRESS_ITEM = "SITE__ITEM_PRESS",
  PRESS_LOGO = "SITE__PRESS_LOGO",
}

export enum ProfileEvent {
  AVATAR = "PROFILE__AVATAR",
  UPLOAD_AVATAR = "PROFILE__UPLOAD_AVATAR",
  UPLOAD_AGE_VERIFY = "PROFILE__UPLOAD_AGE_VERIFY",
  PRESS_SUGGESTED_USER = "PROFILE__PRESS_SUGGESTED_USER",
  PRESS_BUTTON = "PROFILE__PRESS_BUTTON",
  EDIT_PROFILE = "PROFILE__EDIT_PROFILE",
  UPLOAD_VOICE = "PROFILE__UPLOAD_VOICE",
  PLAY_VOICE = "PROFILE__PLAY_VOICE",
  CREATE_ALBUM = "PROFILE__CREATE_ALBUM",
  UPLOAD_ALBUM_IMAGES = "PROFILE__UPLOAD_ALBUM_IMAGES",
  EDIT_ALBUM = "PROFILE__EDIT_ALBUM",
}

export enum MessagesEvent {
  SEND_MESSAGE = "MESSAGES__SEND_MESSAGE",
}

export enum SearchEvent {
  SEARCH_USERS_WITH_FILTER = "SEARCH__SEARCH_USERS_WITH_FILTER",
}

/**
 * Record press event instead of actual payment.
 * Actual payment and credit spending (putVirtualPayment mutation -> DB CreditLog) are recorded in DB
 */
export enum PaymentEvent {
  PRESS_SPEND_CREDIT_BUTTON = "PAYMENT__PRESS_SPEND_CREDIT_BUTTON",
  PRESS_SUBSCRIBE = "PAYMENT__PRESS_SUBSCRIBE",
  PRESS_BUY_CREDIT = "PAYMENT__PRESS_BUY_CREDIT",
  PRESS_FX_BIT = "PAYMENT__PRESS_FX_BIT",
  PRESS_OTCXPERT = "PAYMENT__PRESS_OTCXPERT",
}

export type LoggerEvent =
  | GraphqlEvent
  | CloudinaryEvent
  | AuthEvent
  | UserEvent
  | SiteEvent
  | ProfileEvent
  | MessagesEvent
  | SearchEvent
  | PaymentEvent;
