import { useEffect, useState } from "react";

const checkIsFromApp = () =>
  typeof window !== "undefined" && (window as any).fromApp;

export const useIsFromApp = () => {
  const [isFromApp, setIsFromApp] = useState(false);

  useEffect(() => {
    setIsFromApp(checkIsFromApp());
  }, []);

  return isFromApp;
};
