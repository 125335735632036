import { MenuIcon } from "@heroicons/react/outline";
import { useState } from "react";
import { NavContainer } from "@/featuresPrincess/layout/NavContainer";
import { usePublicNavigationItems } from "@/features/common/hooks/usePublicNavigationItems";
import { Accordion } from "@princess/ui/accordion";
import { Sheet, SheetContent, SheetTrigger } from "@princess/ui/sheet";
import { Logo } from "@/featuresPrincess/layout/Logo";
import { NavItemWithSubItems } from "@/features/common/layout/header/NavItemWithSubItems";
import { NavItem } from "@/features/common/layout/header/NavItem";
import { LanguageOptionDropdown } from "@/features/common/layout/header/LanguageOptionDropdown";
import { MobileNavAccordionItem } from "@/features/common/layout/header/MobileNavAccordionItem";
import { LanguageOptionAccordionItem } from "@/features/common/layout/header/LanguageOptionAccordionItem";
import { LoginButton } from "@/featuresPrincess/common/button/LoginButton";

export const PublicHeader = () => {
  const publicNavigationItems = usePublicNavigationItems();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const closeMobileMenu = () => setIsMobileMenuOpen(false);

  return (
    <NavContainer>
      <div className="hidden items-center justify-end gap-6 text-sm lg:flex lg:w-0 lg:flex-1">
        {publicNavigationItems.map((item) =>
          item.subItems ? (
            <NavItemWithSubItems key={item.title} {...item} />
          ) : (
            <NavItem key={item.title} {...item} />
          ),
        )}
        <LanguageOptionDropdown />
      </div>

      <div className="flex gap-3.5">
        <LoginButton />
        <div className="flex items-center space-x-6 lg:hidden">
          <Sheet open={isMobileMenuOpen} onOpenChange={setIsMobileMenuOpen}>
            <SheetTrigger>
              <MenuIcon
                className="group-hover:text-primary-500 h-5 w-5 text-gray-700"
                aria-hidden="true"
              />
            </SheetTrigger>

            <SheetContent className="space-y-4 bg-white px-0 pt-3">
              <div className="px-5">
                <Logo />
              </div>

              <Accordion type="multiple">
                {publicNavigationItems.map((item) =>
                  item.subItems ? (
                    <MobileNavAccordionItem
                      key={item.title}
                      {...item}
                      onSubItemClick={closeMobileMenu}
                    />
                  ) : (
                    <NavItem
                      key={item.title}
                      onClick={closeMobileMenu}
                      {...item}
                      className="w-full px-5 py-2 hover:bg-gray-100 hover:text-gray-700"
                    />
                  ),
                )}
                <LanguageOptionAccordionItem
                  onLanguageChange={closeMobileMenu}
                />
              </Accordion>
            </SheetContent>
          </Sheet>
        </div>
      </div>
    </NavContainer>
  );
};
