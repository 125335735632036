import { ApolloProvider } from "@apollo/client";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import { withPasswordProtect } from "@storyofams/next-password-protect";
import { appWithTranslation } from "next-i18next";
import App, {
  type AppContext,
  type AppProps,
  type AppInitialProps,
} from "next/app";
import Head from "next/head";
import NextNprogress from "nextjs-progressbar";
import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import "react-medium-image-zoom/dist/styles.css";
import "react-notion-x/src/styles.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { princessTheme } from "@/consts/theme";
import { GraphqlClientProvider } from "@/contexts/graphqlClientContext";
import { LocationProvider } from "@/contexts/locationContext";
import { QueryParamProvider } from "@/contexts/queryParam";
import { TailwindLayout } from "@/featuresPrincess/layout";
import { apolloClient } from "@/helpers/apolloClient";
import { getPublicConfig } from "@/helpers/getPublicConfig";
import { UserProvider } from "@/oldFeatures/auth/hooks/userContext";
import { CustomSnackbarContext } from "@/oldFeatures/auth/hooks/customSnackbarContext";
import { PrincessAuthDialogProvider } from "@/oldFeatures/tailwind/components/PrincessAuthDialogContext";
import { initDatadogLogs } from "@/services/datadogLogs";
import { initFirebase } from "@/services/firebase";
import { trpc } from "@/utils/trpc";
import { Analytics } from "@vercel/analytics/react";
import "@/styles/StripeStyles.css";
import "@/styles/globals.css";
import "@/modules/image-viewer/react-medium-image-zoom.css";
import { OnboardingWrapper } from "@/features/auth/components/onboarding/OnboardingWrapper";
import { useRouter } from "next/router";
import { animateScroll } from "react-scroll";
import { DomainProvider } from "@/helpers/useDomainContext";
import { SiteSeo } from "@/oldFeatures/common/components/SiteSeo";
import { useGraphqlReactQueryUtils } from "@/contexts/useGraphqlReactQueryUtils";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ProjectConfigContextProvider } from "@/contexts/ProjectConfigContext";
import { Toaster } from "@/modules/client/components/feedback/toast";

const { passwordProtect, hostname } = getPublicConfig();

(BigInt.prototype as any).toJSON = function () {
  return this.toString();
};

initDatadogLogs();
initFirebase();

function MyApp({
  Component,
  pageProps,
  err, // Ref: https://github.com/vercel/next.js/blob/canary/examples/with-sentry/pages/_app.js
}: AppProps & AppInitialProps & { err?: any }) {
  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector("#jss-server-side");
    if (jssStyles) {
      jssStyles?.parentElement?.removeChild(jssStyles);
    }
  }, []);

  const DefaultLayout = TailwindLayout;

  const [isIphone, setIsIphone] = useState(false);
  useEffect(() => {
    setIsIphone(navigator.userAgent.indexOf("iPhone") > -1);
  }, []);

  // Start of [PD-2563] - navigation後唔識碌到最頂
  const router = useRouter();
  useEffect(() => {
    const handleRouteChange = (url, { shallow, scroll, ...rest }) => {
      // console.log(
      //   `App is changing to ${url} shallow: ${shallow}, scroll: ${scroll}`,
      // );
      // Example log from above code:
      // App is changing to / shallow: false, scroll: undefined

      animateScroll.scrollTo(0, { containerId: "main", duration: 0 });
    };

    router.events.on("routeChangeComplete", handleRouteChange);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router]);
  // End of [PD-2563] - navigation後唔識碌到最頂

  const { context } = useGraphqlReactQueryUtils();

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content={`width=device-width, initial-scale=1, ${
            isIphone ? "maximum-scale=1" : ""
          }`}
        />
      </Head>

      <ReactQueryDevtools initialIsOpen={false} context={context} />

      <SiteSeo hostname={hostname} />
      <ApolloProvider client={apolloClient}>
        <QueryParamProvider>
          <DomainProvider hostname={hostname}>
            <ThemeProvider theme={princessTheme}>
              <CssBaseline />
              <NextNprogress color={princessTheme.palette.primary.main} />
              <LocationProvider>
                <CustomSnackbarContext>
                  <UserProvider>
                    <GraphqlClientProvider>
                      <PrincessAuthDialogProvider>
                        <ProjectConfigContextProvider>
                          <DefaultLayout>
                            <OnboardingWrapper>
                              <Component {...pageProps} err={err} />
                            </OnboardingWrapper>
                            <Toaster
                              toastOptions={{
                                style: {
                                  backgroundColor: "#404040",
                                },
                              }}
                            />
                            <Analytics />
                          </DefaultLayout>
                        </ProjectConfigContextProvider>
                      </PrincessAuthDialogProvider>
                    </GraphqlClientProvider>
                  </UserProvider>
                </CustomSnackbarContext>
              </LocationProvider>
            </ThemeProvider>
          </DomainProvider>
        </QueryParamProvider>
      </ApolloProvider>
    </>
  );
}

MyApp.getInitialProps = async (appContext: AppContext) => {
  const { ctx, Component } = appContext;

  const appProps = await App.getInitialProps(appContext);

  let pageProps = {};

  if (Component.getInitialProps) {
    pageProps = await Component.getInitialProps(ctx);
  }

  const { req } = ctx;
  let host = req?.headers.host;
  if (host) {
    pageProps = { host, ...pageProps };
  }

  return { ...appProps, pageProps };
};

const translatedApp = appWithTranslation(MyApp);
const trpcApp = trpc.withTRPC(translatedApp);
const passwordProtectedApp = passwordProtect
  ? withPasswordProtect(trpcApp, {
      loginApiUrl: "/api/login",
      checkApiUrl: "/api/passwordCheck",
    })
  : trpcApp;
export default passwordProtectedApp;
