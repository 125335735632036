import { IoNotifications } from "react-icons/io5";
import { Text } from "@princess/ui/custom/text";

type EmptyNotificationListDisplayProps = {
  message?: string;
};
export const EmptyNotificationListDisplay = ({
  message,
}: EmptyNotificationListDisplayProps) => {
  return (
    <div className="flex flex-col items-center justify-center gap-4">
      <IoNotifications className="h-28 w-28 text-gray-200" />
      <Text>{message ?? "暫無通知消息"}</Text>
    </div>
  );
};
