import { TncAndPrivacyLinks } from "@/features/common/TncAndPrivacyLinks";
import { Checkbox } from "@princess/ui/checkbox";
import { Text } from "@princess/ui/custom/text";
import { Trans } from "next-i18next";
import { type ComponentProps } from "react";

type TncAndPrivacyAgreementCheckboxProps = { error?: string } & ComponentProps<
  typeof Checkbox
>;

export const TncAndPrivacyAgreementCheckbox = ({
  error,
  ...props
}: TncAndPrivacyAgreementCheckboxProps) => {
  return (
    <div className="space-y-1">
      <div className="flex items-center space-x-2">
        <Checkbox id="terms" {...props} />
        <Text as="label" htmlFor="terms" size="sm">
          <Trans
            i18nKey="user:tnc.agree_tnc"
            components={{
              tncComponent: <TncAndPrivacyLinks />,
            }}
          />
        </Text>
      </div>
      <Text as="p" className="text-sm text-red-500">
        {error}
      </Text>
    </div>
  );
};
