import { useUserContext } from "@/oldFeatures/auth/hooks";
import { trpc } from "@/utils/trpc";
import {
  defaultProjectConfig,
  type ProjectConfig,
} from "@princess/api/src/types/projectConfig";
import { createContext, useContext, type PropsWithChildren } from "react";

type ProjectConfigContext = {
  projectConfig: ProjectConfig;
};

const ProjectConfigContext = createContext<ProjectConfigContext | null>(null);

export const ProjectConfigContextProvider = ({
  children,
}: PropsWithChildren) => {
  const { user: authUser } = useUserContext();
  const projectConfigQuery = trpc.edgeConfig.projectConfig.useQuery(undefined, {
    enabled: !!authUser,
  });

  const projectConfig = projectConfigQuery.data ?? defaultProjectConfig;

  return (
    <ProjectConfigContext.Provider value={{ projectConfig }}>
      {children}
    </ProjectConfigContext.Provider>
  );
};

export const useProjectConfigContext = () => {
  const context = useContext(ProjectConfigContext);

  if (!context) {
    throw new Error(
      "useProjectConfigContext must be used within a ProjectConfigContextProvider",
    );
  }

  return context;
};
