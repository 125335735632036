import { Text } from "@princess/ui/custom/text";
import { useTranslation } from "next-i18next";
import { RecentNotificationList } from "@/features/notification/components/RecentNotificationList";
import { trpc } from "@/utils/trpc";
import { NotificationPopover as WmaUiNotificationPopover } from "@/modules/features/notification/components/NotificationPopover";

export const NotificationPopover = () => {
  const { t } = useTranslation("notification");
  const { data: unreadNotificationCount = 0 } =
    trpc.notification.unreadNotificationCount.useQuery();

  return (
    <WmaUiNotificationPopover
      title={t("notification")}
      unreadNotificationCount={unreadNotificationCount}
      classes={{
        content: "rounded-[12px]",
      }}
    >
      <Text variant="dark" weight="medium" size="lg">
        {t("recent_notification")}
      </Text>

      <RecentNotificationList
        hasUnreadNotification={unreadNotificationCount > 0}
      />
    </WmaUiNotificationPopover>
  );
};
