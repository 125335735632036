import { Route } from "@/consts/Route";
import { replaceRouteWithParams } from "@/helpers/replaceRouteWithParams";
import { cn } from "@princess/ui";
import Link from "next/link";
import { type PropsWithChildren } from "react";

type LinkToUserProfileProps = PropsWithChildren<{
  userId: number;
  enabled?: boolean;
  className?: string;
}>;
export const LinkToUserProfile = ({
  userId,
  enabled = true,
  className,
  children,
}: LinkToUserProfileProps) => {
  if (!enabled) return children;

  return (
    <Link
      className={cn("contents", className)}
      href={`${replaceRouteWithParams(Route.UserProfile, {
        userId,
      })}`}
      passHref
    >
      {children}
    </Link>
  );
};
