import {
  type ExtractFormDataType,
  FormBuilder,
} from "@/modules/form-builders/FormBuilder";
import { GenderEnum } from "@princess/graphql-codegen/graphql-react-query";
import { useTranslation } from "next-i18next";
import WhatsappIcon from "public/assets/icons/whatsapp.svg";
import LineIcon from "public/assets/icons/line.svg";
import TelegramIcon from "public/assets/icons/telegram.svg";
import WechatIcon from "public/assets/icons/wechat.svg";
import { z } from "zod";
import { format, sub } from "date-fns";
import { type ComponentProps, useState, useCallback, useMemo } from "react";
import { useLocationOptions } from "@/features/user/hooks/useLocationOptions";
import { useAvatarUpload } from "@/features/user/hooks/useAvatarUpload";
import { useUserContext } from "@/oldFeatures/auth/hooks";
import { track } from "@/services/track";
import { Route } from "@/consts";
import { useErrorHandling } from "@/helpers/useErrorHandling";
import { useRouter } from "next/router";
import { AvatarUploader } from "@/features/auth/components/onboarding/AvatarUploader";
import { useUserOnboardMutation } from "@princess/graphql-codegen/nexus-types";
import { useGraphqlReactQueryUtils } from "@/contexts/useGraphqlReactQueryUtils";
import { useGraphqlClient } from "@/contexts/graphqlClientContext";
import { TncAndPrivacyAgreementCheckbox } from "./TncAndPrivacyAgreementCheckbox";
import { Button, type ButtonProps } from "@princess/ui/button";
import { cn } from "@/helpers/cn";
import { useDomainContext } from "@/helpers/useDomainContext";

type OnboardingFormProps = {
  onFormSubmitted?: () => void;
};
export const OnboardingForm = ({ onFormSubmitted }: OnboardingFormProps) => {
  const { websiteName } = useDomainContext();
  const { t } = useTranslation(["user"]);
  const [avatar, setAvatar] = useState<string | undefined>();
  const [avatarError, setAvatarError] = useState<string | undefined>();
  const [agreedTerms, setAgreedTerms] = useState(false);
  const [termsAgreementError, setTermsAgreementError] = useState<
    string | undefined
  >();
  const { countryOptions, getDistrictOptions, haveDistrict } =
    useLocationOptions();
  const [districtOptions, setDistrictOptions] = useState(
    getDistrictOptions("hk"),
  );

  const uploadAvatar = useAvatarUpload();
  const { user, refetchUser, setToken } = useUserContext();

  const { context } = useGraphqlReactQueryUtils();
  const { nexusGraphqlClient } = useGraphqlClient();
  const { mutateAsync: userOnboard } = useUserOnboardMutation(
    nexusGraphqlClient,
    {
      context,
    },
  );
  const { handleGraphqlError } = useErrorHandling();
  const router = useRouter();

  const formFields = useMemo(
    () =>
      ({
        nickname: {
          label: t("field.nickname"),
          required: true,
          type: "name",
          placeholder: "",
          validation: z.string().nonempty({
            message: t("nickname.must_not_empty"),
          }),
        },
        gender: {
          label: t("gender.label"),
          type: "radio",
          options: [
            { label: t("gender.m"), value: GenderEnum.M },
            { label: t("gender.f"), value: GenderEnum.F },
          ],
          defaultValue: GenderEnum.M,
        },
        birthday: {
          label: t("dayOfBirth.onboarding_label"),
          required: true,
          type: "birthday",
          placeholder: t("field.select_placeholder", {
            ns: "common",
          }),
          validation: z.date().max(sub(new Date(), { years: 18 }), {
            message: t("dayOfBirth.must_above_eighteen"),
          }),
        },
        country: {
          label: t("country.onboarding_label"),
          required: true,
          type: "select",
          options: countryOptions,
          defaultValue: "hk",
        },
        district: {
          type: "select",
          label: t("district.label"),
          watch: "country",
          options: districtOptions,
          onWatchChange: (value) => {
            setDistrictOptions(getDistrictOptions(value.country));
          },
          required: true,
          placeholder: t("field.select_placeholder", {
            ns: "common",
          }),
          validation: z.string().min(1, {
            message: t("district.must_not_empty"),
          }),
        },
        contactMethods: {
          label: t("contactMethods.onboarding_label"),
          type: "section",
        },
        whatsapp: {
          type: "text",
          placeholder: t("contactMethods.whatsapp"),
          icon: <WhatsappIcon />,
          iconMasked: true,
          hideLabel: true,
        },
        telegram: {
          type: "text",
          placeholder: t("contactMethods.telegram"),
          icon: <TelegramIcon />,
          iconMasked: true,
          hideLabel: true,
        },
        wechat: {
          type: "text",
          placeholder: t("contactMethods.wechat"),
          icon: <WechatIcon />,
          iconMasked: true,
          hideLabel: true,
        },
        line: {
          type: "text",
          placeholder: t("contactMethods.line"),
          icon: <LineIcon />,
          iconMasked: true,
          hideLabel: true,
        },
      }) satisfies ComponentProps<typeof FormBuilder>["fields"],
    [countryOptions, districtOptions, getDistrictOptions, t],
  );

  const clearErrors = useCallback(() => {
    setAvatarError(undefined);
    setTermsAgreementError(undefined);
  }, []);

  const onSubmit = useCallback(
    async (data: ExtractFormDataType<typeof formFields>) => {
      if (!user) return;

      clearErrors();

      if (!agreedTerms) {
        setTermsAgreementError(
          t("tnc.must_agree", {
            websiteName,
          }),
        );
        return;
      }

      if (!avatar) {
        setAvatarError(t("avatar.required"));
        return;
      }

      const avatarResponse = await uploadAvatar({
        url: avatar,
        userId: user.userId,
      });

      if (!avatarResponse) return;

      try {
        track("register", { gender: data.gender as string });
        const {
          userOnboard: { idToken },
        } = await userOnboard({
          data: {
            gender: data.gender,
            dob: format(data.birthday, "yyyy-MM-dd"),
            nickname: data.nickname,
            line: data.line,
            telegram: data.telegram,
            wechat: data.wechat,
            whatsapp: data.whatsapp,
            city: data.district,
            avatar: avatarResponse?.secure_url,
          },
        });

        setToken(idToken);
        await refetchUser();
        if (
          router.pathname !== Route.UserProfile &&
          router.pathname !== Route.UserProfileReferral
        ) {
          // `/users/:userId/profile/:referral?

          // Was not viewing other's profile, push to own profile

          router.push(Route.MemberHome);
        }
      } catch (e: any) {
        handleGraphqlError({ error: e });
      } finally {
        onFormSubmitted?.();
      }
    },
    [
      user,
      clearErrors,
      agreedTerms,
      avatar,
      uploadAvatar,
      t,
      userOnboard,
      setToken,
      refetchUser,
      router,
      handleGraphqlError,
      onFormSubmitted,
    ],
  );

  return (
    <div className="flex flex-col gap-5">
      <AvatarUploader
        onChange={(file) => setAvatar(file?.url)}
        preview={avatar}
        error={avatarError}
      />
      <FormBuilder
        layout={[
          "nickname",
          "gender",
          "birthday",
          ["country", "district"],
          "contactMethods",
          ["whatsapp", "telegram"],
          ["wechat", "line"],
        ]}
        fields={formFields}
        schemaExtension={(schema) =>
          schema
            .refine(
              ({ whatsapp, telegram, wechat, line }) =>
                whatsapp || telegram || wechat || line,
              {
                path: ["contactMethods"],
                message: t("contactMethods.at_least_one"),
              },
            )
            .refine(
              ({ country, district }) => {
                return haveDistrict(country) ? !!district : true;
              },
              {
                path: ["district"],
                message: t("district.must_not_empty"),
              },
            )
        }
        onSubmit={onSubmit}
        actionButtonLabel={t("action.confirm", {
          ns: "common",
        })}
        footer={
          <TncAndPrivacyAgreementCheckbox
            error={termsAgreementError}
            checked={agreedTerms}
            onCheckedChange={(checked) => setAgreedTerms(checked === true)}
          />
        }
        components={{
          button: (props: ButtonProps) => (
            <Button {...props} className={cn(props.className, "mt-1 w-full")} />
          ),
        }}
      />
    </div>
  );
};
