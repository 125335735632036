import React from "react";

interface ContentHeaderProps {
  title: string;
  children?: React.ReactNode;
}
export const ContentHeader = ({ title, children }: ContentHeaderProps) => {
  return (
    <div className="border-primary-200 flex items-center justify-center rounded border bg-white p-5">
      <div className="text-primary-dark text-xl"> {title} </div>
      {children}
    </div>
  );
};
