import { Route } from "@/consts";
import { useTranslation } from "next-i18next";
import { useMemo } from "react";
import FriendIcon from "public/assets/icons/friend.svg";
import SignOutIcon from "public/assets/icons/sign_out.svg";
import SupportIcon from "public/assets/icons/support.svg";
import CreditCardIcon from "public/assets/icons/credit_card.svg";
import CreditIcon from "public/assets/icons/credit_gold.svg";
import { TbSettings } from "react-icons/tb";
import { CrownNewIcon } from "@/oldFeatures/common/components";
import { UserRoleEnum } from "@princess/graphql-codegen/graphql-hooks";
import { useUserContext } from "@/oldFeatures/auth/hooks";
import { Button } from "@princess/ui/button";
import { useRouter } from "next/router";

export const useUserNavigationItems = () => {
  const { t } = useTranslation("common");
  const { user, logout } = useUserContext();
  const router = useRouter();

  return useMemo(
    () => [
      {
        title: t("common:menu.friend"),
        icon: FriendIcon,
        href: Route.Friend,
      },
      {
        title: t("common:menu.credits"),
        icon: CreditIcon,
        href: Route.Recharge,
      },
      {
        title: t("common:menu.payment"),
        subtitle: (onClick: () => void) =>
          user?.role !== UserRoleEnum.Premium && (
            <Button
              onClick={(e) => {
                e.preventDefault();
                router.push(Route.Premium);
                onClick();
              }}
              className="filled flex h-fit items-center gap-2 py-1 text-sm"
            >
              <CrownNewIcon className="h-4 w-4" />
              <span>{t("menu.premium_short")}</span>
            </Button>
          ),
        icon: CreditCardIcon,
        href: Route.PaymentSetting,
      },
      {
        title: t("common:menu.settings"),
        icon: TbSettings,
        href: Route.ProfileSetting,
      },
      {
        title: t("support:title"),
        icon: SupportIcon,
        href: Route.Faq,
      },
      {
        title: t("common:menu.sign_out"),
        icon: SignOutIcon,
        onClick: logout,
      },
    ],
    [logout, router, t, user?.role],
  );
};
