import { Route } from "@/consts";
import { getPublicConfig } from "@princess/api/src/helpers/getPublicConfig";
import { useTranslation } from "react-i18next";

export const useFooterItems = () => {
  const { hostname, features } = getPublicConfig();
  const { t } = useTranslation("common");

  const aboutUsItems = [
    {
      label: t("footer.link_about"),
      path: Route.AboutUs,
    },

    {
      label: t("footer.link_faq"),
      path: Route.Faq,
    },
  ];

  const socialMediaItems = [
    {
      label: t("footer.link_facebook"),
      path: features.sns.facebook[hostname],
    },
    {
      label: t("footer.link_instagram"),
      path: features.sns.instagram[hostname],
    },
  ];

  const navigationItems = [
    {
      label: t("footer.link_guideline"),
      path: Route.CommunityGuidelines,
    },
    {
      label: t("footer.link_dating_safety"),
      path: Route.SafetyTips,
    },
    {
      label: t("footer.link_safety_policy"),
      path: Route.SafetyPolicy,
    },
  ];

  return {
    aboutUsItems,
    socialMediaItems,
    navigationItems,
  };
};
