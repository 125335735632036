import { IconButton, makeStyles } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { SnackbarProvider } from "notistack";
import React, { createRef } from "react";

const useStyles = makeStyles((theme) => ({
  primary: {
    backgroundColor: theme.palette.primary.main,
  },
  message: {
    display: "flex",
    alignItems: "center",
  },
  icon: {
    fontSize: 20,
    color: "white",
  },
}));

export const notistackRef = createRef<SnackbarProvider>();

/**
 *  @deprecated this is using material UI
 */
export const CustomSnackbarContext = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const classes = useStyles();

  return (
    // @ts-ignore
    <SnackbarProvider
      ref={notistackRef}
      maxSnack={3}
      classes={{
        variantSuccess: classes.primary,
        variantInfo: classes.primary,
        variantWarning: classes.primary,
        variantError: classes.primary,
      }}
      variant="success"
      action={(key) => (
        <IconButton
          className={classes.icon}
          onClick={() => {
            notistackRef.current?.closeSnackbar(key);
          }}
        >
          <Close />
        </IconButton>
      )}
    >
      {children}
    </SnackbarProvider>
  );
};
