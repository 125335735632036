import { getPublicConfig } from "@/helpers/getPublicConfig";
import { useLocalStorage } from "react-use";
import * as Sentry from "@sentry/nextjs";
import { useCallback, useMemo } from "react";

const { authHeaderKey } = getPublicConfig();

export const useAuthToken = () => {
  const [token, _setToken, removeToken] = useLocalStorage<string>(
    authHeaderKey,
    "",
    { raw: true },
  );

  const setToken = useCallback(
    (value?: string) => {
      try {
        _setToken(value);
      } catch (e) {
        Sentry.withScope((scope) => {
          scope.setTag("type", "LOCALSTORAGE");
          Sentry.captureException(e);
        });
      }
    },
    [_setToken],
  );

  return useMemo(
    () => ({
      token,
      setToken,
      removeToken,
    }),
    [removeToken, setToken, token],
  );
};
