import { useTranslation } from "next-i18next";
import Image from "next/image";
import { useImageDropzone } from "@/oldFeatures/common/components/SingleImageUploadField";
import { Label } from "@princess/ui/label";
import { IconButton } from "@princess/ui/custom/iconButton";
import { Text } from "@princess/ui/custom/text";
import { cn } from "@/lib/utils";
import { HiCamera } from "react-icons/hi2";

type ImageFile = { file: File; url: string };

type AvatarUploaderProps = {
  onChange: (file?: ImageFile) => void;
  preview: string | undefined;
  error?: string;
};

export const AvatarUploader = ({
  onChange,
  preview,
  error,
}: AvatarUploaderProps) => {
  const { t } = useTranslation(["user", "common"]);
  const { getInputProps, getRootProps, open } = useImageDropzone(onChange, {
    noClick: true,
    noDrag: true,
  });

  return (
    <div className="flex flex-col gap-1">
      <Label>
        {t("avatar.label")}
        <span className="text-destructive"> *</span>
      </Label>
      <div
        className={cn("relative h-24 w-24 rounded-full bg-gray-100")}
        {...getRootProps()}
      >
        <div className="relative h-full w-full overflow-hidden rounded-full">
          <Image
            src={preview ?? "/assets/images/profile_head.png"}
            fill
            className="object-cover object-center"
            alt="avatar upload"
          />
        </div>
        <div className="absolute bottom-0 right-0">
          <IconButton
            className="h-fit w-fit rounded-full bg-white p-1.5 shadow-md transition duration-300 ease-in-out hover:bg-gray-100"
            onClick={open}
            type="button"
          >
            <button>
              <HiCamera size={24} className="text-gray-700" />
            </button>
          </IconButton>
        </div>
        <input {...getInputProps()} />
      </div>
      {error && (
        <Text className="text-red-500" size="sm">
          {error}
        </Text>
      )}
    </div>
  );
};
