import { trpc } from "@/utils/trpc";
import { useEffect } from "react";

export const usePrefetchAppData = () => {
  const utils = trpc.useUtils();

  useEffect(() => {
    void utils.gift.gifts.prefetch();
    void utils.edgeConfig.projectConfig.prefetch();
    void utils.product.products.prefetch();
  }, [
    utils.edgeConfig.projectConfig,
    utils.gift.gifts,
    utils.product.products,
  ]);
};
