import { getPublicConfig } from "@/helpers/getPublicConfig";
import { datadogLogs } from "@datadog/browser-logs";

const { env, project, service } = getPublicConfig();

export function initDatadogLogs() {
  datadogLogs.init({
    service: project.projectSlug,
    clientToken: service.datadogLogs.clientToken,
    site: "datadoghq.com",
    forwardErrorsToLogs: false,
    sessionSampleRate: 100,
    env: env.vercelEnv,
  });
}
