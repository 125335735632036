import { createTheme } from "@material-ui/core/styles";

/**
 *  @deprecated this is using material UI
 */
export const princessTheme = createTheme({
  overrides: {
    MuiButton: {
      root: { boxShadow: "0px 0px 0px 0px" },
      contained: { boxShadow: "0px 0px 0px 0px", borderRadius: 8 },
    },
    MuiDialog: {
      paper: { margin: 8 },
      paperFullScreen: { margin: 0 /*, height: '80%' */ },
      paperFullWidth: { width: "100%" },
    },
    MuiDialogContent: { root: { padding: 0 } },
    MuiDialogTitle: {
      root: {
        boxShadow:
          "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: "rgb(255,225,223)",
        marginTop: 8,
        marginBottom: 8,
      },
    },
    MuiInput: {
      root: { borderBottom: 0, borderRadius: 8, padding: 2, paddingLeft: 10 },
      underline: { borderBottom: 0 },
      inputMultiline: { padding: 8 },
    },
    MuiSelect: {
      icon: { color: "rgb(254,181,180)" },
      select: { textAlign: "center" },
    },
    MuiSvgIcon: { root: { verticalAlign: "text-bottom" } },
    MuiTab: { root: { color: "#888" } },
    MuiTooltip: { tooltip: { backgroundColor: "rgb(165, 127, 213)" } },
  },
  palette: {
    primary: {
      main: "rgb(214, 157, 67)",
      dark: "rgb(167, 117, 37)",
      light: "rgba(214, 157, 67, 0.5)",
      contrastText: "#FFF",
    },
    text: { secondary: "#888" },
  },
  typography: {
    allVariants: {
      color: "#444",
      fontFamily: '"Roboto", "Helvetica", font61255, "Arial", sans-serif',
      userSelect: "none",
    },
  },
});
