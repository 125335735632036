import { getPublicConfig } from "@/helpers/getPublicConfig";
import Link from "next/link";
import AppStoreIcon from "public/assets/icons/app_store.svg";
import PlayStoreIcon from "public/assets/icons/play_store.svg";
import SmallAppStoreIcon from "public/assets/icons/app_store_small.svg";
import SmallPlayStoreIcon from "public/assets/icons/play_store_small.svg";
import { useMemo } from "react";

const { installAppLink } = getPublicConfig();

export const InstallAppBadge = ({
  os,
  variant = "large",
}: {
  os: "ios" | "android";
  variant?: "small" | "large";
}) => {
  const icon = useMemo(() => {
    if (os === "ios") {
      return variant === "small" ? <SmallAppStoreIcon /> : <AppStoreIcon />;
    }

    return variant === "small" ? <SmallPlayStoreIcon /> : <PlayStoreIcon />;
  }, [os, variant]);

  return (
    <Link
      className="hover:opacity-70"
      target="_blank"
      href={os === "ios" ? installAppLink.ios : installAppLink.android}
    >
      {icon}
    </Link>
  );
};
