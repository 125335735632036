import { cn } from "@/helpers/cn";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";
import { useCallback } from "react";
import { Fragment } from "react";

type NullableValue<T, Nullable> = Nullable extends true ? T | null : T;
interface SelectInputProps<
  T extends string | object,
  Nullable extends boolean,
> {
  /**
   * If nullable is true, the value and onChange will be nullable true
   */
  nullable?: Nullable;
  options: T[];
  /**
   * Select input label
   */
  label?: string;
  value: NullableValue<T, Nullable>;

  onChange: (v: NullableValue<T, Nullable>) => void;
  nullOptionLabel?: string;

  /**
   * Extracts the key from the label
   * If options is array of string, we do not need a label key selector
   */
  optionLabelKey?: T extends object ? keyof T : never;

  className?: string;
}

/**
 * Custom select input that handles nullable select, accepts string or options
 */
export function SelectInput<
  T extends string | object,
  Nullable extends boolean = false,
>(props: SelectInputProps<T, Nullable>) {
  const {
    options,
    label,
    value,
    onChange,
    optionLabelKey,
    nullable,
    nullOptionLabel = "Unselected",
    className,
  } = props;

  const _options = nullable ? [null, ...options] : options;

  const getOptionLabel = useCallback(
    (option: T) =>
      typeof option === "string" ? option : option[optionLabelKey as keyof T],
    [optionLabelKey],
  );

  return (
    <Listbox value={value} onChange={onChange}>
      {({ open }) => (
        <div className={className}>
          {label && (
            <Listbox.Label className="mb-1 block text-sm font-normal text-gray-500">
              {label}
            </Listbox.Label>
          )}
          <div className="relative">
            <Listbox.Button className="form-text-input cursor-default text-left">
              {value === null ? (
                <span className="block truncate text-gray-400">
                  {nullOptionLabel}
                </span>
              ) : (
                <span className="block truncate">
                  {/* @ts-ignore */}
                  {typeof value === "string"
                    ? value
                    : (value as T)[optionLabelKey as keyof T]}
                </span>
              )}

              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <SelectorIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {_options.map((option, i) => (
                  <Listbox.Option
                    key={i}
                    className={({ active }) =>
                      cn(
                        active ? "bg-primary-500 text-white" : "text-gray-900",
                        "relative cursor-default select-none py-2 pl-3 pr-9 text-left",
                      )
                    }
                    value={option}
                  >
                    {({ selected, active }) => (
                      <>
                        {option === null ? (
                          <span className="block truncate font-normal text-gray-300">
                            Unselected
                          </span>
                        ) : (
                          <span
                            className={cn(
                              selected ? "font-semibold" : "font-normal",
                              "block truncate",
                            )}
                          >
                            {/* @ts-ignore */}
                            {getOptionLabel(option)}
                          </span>
                        )}

                        {selected ? (
                          <span
                            className={cn(
                              active ? "text-white" : "text-primary-500",
                              "absolute inset-y-0 right-0 flex items-center pr-4",
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </div>
      )}
    </Listbox>
  );
}
