import { useQuery, type UseQueryOptions } from "@tanstack/react-query";
import { z } from "zod";

/* Suggested feature flags schema:

type FeatureFlag = {
  featureFlagName: {
    enabled: boolean;
    whitelist: string[];
  };
};

in zod way:

featureFlagName: z
  .object({
    enabled: z.boolean().default(false),
    whitelist: z.array(z.string()).default([]),
  })
  .default({}),

*/
export const princessFeatures = z.object({
  features: z
    .object({
      // add new feature flags below
      savingCreditCardInfo: z
        .object({
          enabled: z.boolean().default(false),
          whitelist: z.array(z.string()).default([]),
        })
        .default({}),
      newSearchUserPage: z
        .object({
          web: z.object({
            enabled: z.boolean().default(false),
          }),
        })
        .optional(),
      anonymousRate: z
        .object({
          enabled: z.boolean().default(false),
        })
        .optional(),
      collectNfmq: z
        .object({
          enabled: z.boolean().default(false),
        })
        .optional(),
      newAlbum: z
        .object({
          web: z.object({
            enabled: z.boolean().default(false),
            whitelist: z.array(z.number()).default([]),
          }),
          app: z.object({
            enabled: z.boolean().default(false),
            whitelist: z.array(z.number()).default([]),
          }),
        })
        .optional(),
    })
    .default({}),
});

export type PrincessFeatures = z.infer<typeof princessFeatures>;

export const useFeatureQuery = <
  TFeatureKey extends keyof PrincessFeatures["features"],
>(
  feature: TFeatureKey,
  options?: { baseUrl?: string },
  useQueryOptions?: Pick<UseQueryOptions<PrincessFeatures>, "enabled">,
) => {
  return useQuery({
    queryKey: ["features"],
    queryFn: () =>
      fetch(`${options?.baseUrl ?? ""}/api/features`).then(
        (res) => res.json() as Promise<PrincessFeatures>,
      ),
    select: (data) => {
      return data.features[feature];
    },
    ...useQueryOptions,
  });
};
