import { Skeleton } from "@princess/ui/skeleton";

type NotificationListSkeletonsProps = {
  count?: number;
};
export const NotificationListSkeletons = ({
  count = 5,
}: NotificationListSkeletonsProps) => {
  return (
    <div className="space-y-4">
      {Array.from({ length: count }).map((_, i) => (
        <div
          key={i}
          className="border-b border-gray-500/15 pb-4 pr-4 last:border-b-0"
        >
          <div className="flex items-center gap-4">
            <Skeleton className="h-12 w-12 flex-shrink-0 rounded-full" />

            <div className="flex w-full flex-col gap-1">
              <Skeleton className="h-4 w-full" />
              <Skeleton className="h-4 w-20" />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
