import { type PageFilter } from "@/oldFeatures/findUser/components";
import {
  AuthEvent,
  MessagesEvent,
  PaymentEvent,
  ProfileEvent,
  SearchEvent,
  SiteEvent,
  UserEvent,
} from "@/oldFeatures/ga";
import {
  type PaymentPlanEnum,
  type PermissionEnum,
  type SubscriptionPlanEnum,
  type UserSearchInput,
} from "@princess/graphql-codegen/graphql-hooks";
import firebase from "firebase/app";

type NormalProvider = "phone" | "email";
type SocialProvider = "facebook";
type Provider = NormalProvider | SocialProvider;
type EventLogger = (eventName: string, params?: Record<string, any>) => void;

/**
 * A wrapper function to generate different event loggers.
 * We can provider a custom logger function to inject common parameters into event params, like role and gender.
 * @see useLoggers.ts
 *
 * If injection is not needed, getLoggers can be called anywhere
 *
 * @param eventLogger
 */
export const getLoggers = (eventLogger?: EventLogger) => {
  const logger = eventLogger ?? firebase.analytics().logEvent ?? console.log;

  const authLogger = {
    login: (provider: Provider) => {
      logger(AuthEvent.LOGIN, { provider });
    },
    register: (provider: Provider) => {
      logger(AuthEvent.REGISTER, { provider });
    },
    resendVerify: (provider: NormalProvider) => {
      logger(AuthEvent.RESEND_VERIFY, { provider });
    },
    // Phone login verify is new function. Adding action as optinoal param with register as default
    verify: (
      provider: NormalProvider,
      action: "login" | "register" = "register",
    ) => {
      logger(AuthEvent.VERIFY, { provider, action });
    },
  };
  const userLogger = {
    toggleFavourite: (action: "add" | "remove") =>
      logger(UserEvent.FAVOURITE, { action }),

    reportUser: () => logger(UserEvent.REPORT_USER),

    rateUser: (rate: number) => logger(UserEvent.RATE_USER, { rate }),

    friendRequest: (
      action: "wine" | "by_quota" | "accept" | "reject" | "cancel",
    ) => logger(UserEvent.FRIEND_REQUEST, { action }),
  };

  const miscLogger = {
    /**
     * @param item The menu item
     * @param from The current route
     */
    pressMenuItem: (item: string, from: string, params?: Record<string, any>) =>
      logger(SiteEvent.PRESS_ITEM, { item, from, ...params }),

    pressLogo: () => logger(SiteEvent.PRESS_LOGO),
  };

  const profileLogger = {
    avatar: (action: "open" | "swipe") =>
      logger(ProfileEvent.AVATAR, { action }),

    /**
     * @deprecated
     * @param type
     * @returns
     */
    uploadAvatar: (type: "head" | "half" | "full") =>
      logger(ProfileEvent.UPLOAD_AVATAR, { type }),

    uploadAgeVerify: () => logger(ProfileEvent.UPLOAD_AGE_VERIFY),

    suggestedUserPress: () => logger(ProfileEvent.PRESS_SUGGESTED_USER),

    pressButton: (
      button: "get_contact" | "chat" | "upgrade_premium" | "chat_in_profile",
    ) => logger(ProfileEvent.PRESS_BUTTON, { button }),

    editProfile: (section: "personal_info" | "about" | "contact") =>
      logger(ProfileEvent.EDIT_PROFILE, { section }),

    uploadVoice: (action: "record" | "upload") =>
      logger(ProfileEvent.UPLOAD_VOICE, { action }),

    playVoice: (person: "self" | "others") => {
      logger(ProfileEvent.PLAY_VOICE, { person });
    },

    createAlbum: (permission: PermissionEnum) => {
      logger(ProfileEvent.CREATE_ALBUM, { permission });
    },

    uploadAlbumImages: (count: number) => {
      logger(ProfileEvent.UPLOAD_ALBUM_IMAGES, { count });
    },

    editAlbum: () => {
      logger(ProfileEvent.EDIT_ALBUM);
    },
  };

  const messagesLogger = {
    sendMessage: (length: number, hasImage: boolean) =>
      logger(MessagesEvent.SEND_MESSAGE, {
        length,
        hasImage,
      }),
  };

  const searchLogger = {
    /**
     * Log when both search criterial and local filter changed
     * @param criterial Age, location, premium, age verified and verify level
     * @param filter Local filters: height, body, drink and smoke
     */
    searchUsersWithFilter: (criteria: UserSearchInput, filter: PageFilter) =>
      logger(SearchEvent.SEARCH_USERS_WITH_FILTER, {
        criteria,
        filter,
      }),
  };

  const paymentLogger = {
    pressSpendCreditButton: (
      action:
        | "exchange_friend_quota"
        | "convert_to_wine"
        | "exchange_bitcoin"
        | "exchange_usdt"
        | "exchange_eth",
    ) =>
      logger(PaymentEvent.PRESS_SPEND_CREDIT_BUTTON, {
        action,
      }),

    pressSubscribe: (plan: SubscriptionPlanEnum, amount?: number) =>
      logger(PaymentEvent.PRESS_SUBSCRIBE, { plan }),

    pressBuyCredit: (plan: PaymentPlanEnum, amount: number) =>
      logger(PaymentEvent.PRESS_BUY_CREDIT, { plan, amount }),

    pressFxBit: (plan?: PaymentPlanEnum, amount?: number) =>
      logger(PaymentEvent.PRESS_FX_BIT, { plan, amount }),

    pressOtcxpert: (plan?: PaymentPlanEnum, amount?: number) =>
      logger(PaymentEvent.PRESS_OTCXPERT, { plan, amount }),
  };

  return {
    authLogger,
    userLogger,
    miscLogger,
    profileLogger,
    messagesLogger,
    searchLogger,
    paymentLogger,
  };
};
