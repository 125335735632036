// TODO: replace this with useAnalytics, see https://github.com/WeMakeAppSolution/koljobs-web/blob/2bc81811908b98d4f014b2c5ed44864ea3a02132/src/features/common/hooks/useAnalytics.tsx

import { useUserContext } from "@/oldFeatures/auth/hooks";
import { type LoggerEvent, getLoggers } from "@/oldFeatures/ga";
import { AppNameEnum } from "@princess/graphql-codegen/graphql-hooks";
import firebase from "firebase/app";
import { useMemo } from "react";

/**
 * Injects user role and gender into GA events
 */
export const useInjectedLoggers = () => {
  const { user } = useUserContext();
  const userRole = user?.role;
  const userGender = user?.gender;

  const loggers = useMemo(
    () =>
      getLoggers((eventName, params = {}) =>
        firebase.analytics().logEvent(eventName, {
          ...params,
          userRole,
          userGender,
          appName: AppNameEnum.PricessDating,
        }),
      ),
    [userGender, userRole],
  );

  const logEvent = (eventName: LoggerEvent, params = {}) => {
    firebase.analytics().logEvent(eventName, {
      ...params,
      userRole,
      userGender,
      appName: AppNameEnum.PricessDating,
    });
  };

  return {
    /**
     * @deprecated use `logEvent` instead
     */
    loggers,
    /**
     * @deprecated use `logEvent` instead
     */
    ...loggers,
    logEvent,
  };
};
