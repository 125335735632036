/**
 * Similar to `Object.entries` but retains the correct types.
 *
 * Usage:
 *
 * ```ts
 * objectEntries({a: 2}) // the return type is: `Array<['a', number]>`
 * // vs
 * Object.entries({a: 2}) // the return type is: `Array<[string, number]>`
 * ```
 */

export const objectEntries = <T extends {}>(o: T): Array<EntryTuple<T>> => {
  return Object.entries(o) as Array<EntryTuple<T>>;
};

type EntryTuple<T extends {}> = {
  [K in keyof T]-?: [K, T[K]];
}[keyof T];
