import Country from "@princess/common/location/country.json";
import Subdivision from "@princess/common/location/subdivision.json";
import React, { createContext, useContext } from "react";

interface LocationContextType {
  Country: typeof Country;
  Subdivision: typeof Subdivision;

  getSubdivisions: (country: string) => string[];
  validateLocation: (location: string) => boolean;
  getLocationBreakdown: (city: string) => string[] | null;
}

const LocationContext = createContext<LocationContextType>({
  Country,
  Subdivision,
  getSubdivisions: () => [],
  validateLocation: () => false,
  getLocationBreakdown: () => [],
});

export const LocationProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const getSubdivisions = (country: string) =>
    Object.keys(Subdivision).filter((subdiv) =>
      subdiv.startsWith(`${country}_`),
    );

  const validateLocation = (c: string) => {
    const [country, subdivision] = c.split("_");

    if (!country) return false;
    if (!subdivision && getSubdivisions(country).length > 0) return false;
    return true;
  };

  const getLocationBreakdown: (c: string) => string[] | null = (c) => {
    if (!validateLocation(c)) return null;

    const [country = "", subdivision = ""] = c.split("_");

    if (!country) return null;
    return [country, !!subdivision ? `${country}_${subdivision}` : subdivision];
  };

  return (
    <LocationContext.Provider
      value={{
        Country,
        Subdivision,
        getSubdivisions,
        validateLocation,
        getLocationBreakdown,
      }}
    >
      {children}
    </LocationContext.Provider>
  );
};

export const useLocationContext = () => useContext(LocationContext);
