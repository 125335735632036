import { useGraphqlClient } from "@/contexts/graphqlClientContext";
import { useGraphqlReactQueryUtils } from "@/contexts/useGraphqlReactQueryUtils";
import { getPublicConfig } from "@/helpers/getPublicConfig";
import { useUserContext } from "@/oldFeatures/auth/hooks/userContext";
import {
  GenderEnum,
  UserRateLevelEnum,
} from "@princess/graphql-codegen/graphql-react-query";
import {
  useCollectMonthlyNfmqMutation,
  useHasIssuedNfmqThisMonthQuery,
} from "@princess/graphql-codegen/nexus-types";
import { useFeatureQuery } from "@princess/helpers/src/hooks/useFeatureQuery";
import { useCallback, useMemo } from "react";

const {
  features: {
    monthlyGift: { maxNfmqEligibleForCollection },
  },
} = getPublicConfig();

export const useGiftCollection = (options?: { onSuccess?: () => void }) => {
  const { data: collectNfmqFeature } = useFeatureQuery("collectNfmq");
  const { onSuccess } = options ?? {};
  const { refetchUser, user } = useUserContext();
  const { context } = useGraphqlReactQueryUtils();
  const { nexusGraphqlClient } = useGraphqlClient();
  const {
    data: hasCollected,
    isLoading: isFetchingHasCollected,
    refetch: refetchHasCollected,
  } = useHasIssuedNfmqThisMonthQuery(nexusGraphqlClient, undefined, {
    context,
    select: (data) => data.hasIssuedNfmqThisMonth?.issued,
    enabled: collectNfmqFeature?.enabled,
  });
  const { mutate: _collectGift, isLoading: isCollectingGift } =
    useCollectMonthlyNfmqMutation(nexusGraphqlClient, {
      context,
      onSuccess: () => {
        onSuccess?.();
        refetchHasCollected();
        refetchUser();
      },
    });

  const collectGift = useCallback(() => {
    _collectGift({});
  }, [_collectGift]);

  const shouldShowGiftCollection = useMemo(
    () =>
      (user?.nonFriendMsgQuota ?? 0) <= maxNfmqEligibleForCollection &&
      user?.gender === GenderEnum.F &&
      user?.rateLevel !== UserRateLevelEnum.Normal &&
      collectNfmqFeature?.enabled,
    [
      user?.nonFriendMsgQuota,
      user?.gender,
      user?.rateLevel,
      collectNfmqFeature?.enabled,
    ],
  );

  return useMemo(
    () => ({
      hasCollected,
      isFetchingHasCollected,
      collectGift,
      isCollectingGift,
      shouldShowGiftCollection,
    }),
    [
      collectGift,
      hasCollected,
      isCollectingGift,
      isFetchingHasCollected,
      shouldShowGiftCollection,
    ],
  );
};
