import { cld } from "@/services/cloudinary";
import { getPublicId } from "@/services/cloudinary/getPublicId";
import { Resize } from "@cloudinary/url-gen/actions";

export const getTransformedUrl = (
  url: string,
  width: number,
  height = width,
) => {
  const publicId = getPublicId(url);

  // temporary fix for avatars that contains incorrect cloudinary ID (PD-3266)
  const isIncorrectCloudinaryId = publicId.endsWith("%5Bobject%20Object%5D");

  const image = cld.image(publicId);
  const resizedImage = image.resize(
    Resize.fit().width(Math.round(width)).height(Math.round(height)),
  );

  const resizedImageUrl = resizedImage.toURL();

  // temporary fix for avatars that contains incorrect cloudinary ID (PD-3266)
  if (isIncorrectCloudinaryId) {
    return resizedImageUrl.replace(
      "%255Bobject%2520Object%255D",
      "%5Bobject%20Object%5D",
    );
  }

  return resizedImageUrl;
};
