import React from "react";

import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import AsyncSelect from "react-select/async";
import AsyncCreatableSelect from "react-select/async-creatable";

type Props = {
  options: Array<OptionType>;
  onChange: (values: Array<OptionType>) => void;
  value: Array<OptionType>;
  placeholder?: string;
  isCreatable?: boolean;
  maxLength?: number;
  onSearch?: (value: string) => Promise<Array<OptionType>>;
};

type OptionType = {
  value: string;
  label: string;
};

export type TagSelectProps = Props;

export const TagSelect: React.FC<Props> = (props) => {
  const maxLength = props.maxLength ?? 0;
  const CreatableSelectComponent = props.isCreatable ? CreatableSelect : Select;
  const AsyncSelectComponent = props.isCreatable
    ? AsyncCreatableSelect
    : AsyncSelect;

  const SelectComponent = props.onSearch
    ? AsyncSelectComponent
    : CreatableSelectComponent;

  const optionsProps = props.onSearch
    ? {
        defaultOptions: props.options,
        loadOptions: props.onSearch,
      }
    : {
        options: props.options,
      };

  return (
    <SelectComponent<OptionType, true>
      onChange={(newValue) =>
        (maxLength === 0 || newValue.length <= maxLength) &&
        props.onChange([...newValue])
      }
      closeMenuOnSelect={false}
      value={props.value}
      isMulti
      placeholder={props.placeholder}
      {...optionsProps}
      styles={{
        control: (base) => ({
          ...base,
          borderRadius: "calc(var(--radius) - 2px)",
          borderColor: "rgb(var(--input))",
        }),
        indicatorSeparator: () => ({
          display: "none",
        }),
        dropdownIndicator: () => ({
          display: "none",
        }),
        option: (base) => ({
          ...base,
          fontSize: "0.875rem",
        }),
        menu: (base) => ({
          ...base,
          fontSize: "0.875rem",
        }),
        menuList: (base) => ({
          ...base,
          fontSize: "0.875rem",
        }),
        menuPortal: (base) => ({
          ...base,
          fontSize: "0.875rem",
        }),
        multiValue: (base) => ({
          ...base,
          fontSize: "0.875rem",
        }),
        placeholder: (base) => ({
          ...base,
          fontSize: "0.875rem",
        }),
        input: (base) => ({
          ...base,
          fontSize: "0.875rem",
        }),
      }}
    />
  );
};
