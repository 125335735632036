import { createEnv } from "@t3-oss/env-nextjs";
import { z } from "zod";

export const env = createEnv({
  /**
   * Specify your server-side environment variables schema here. This way you can ensure the app isn't
   * built with invalid env vars.
   */
  server: {
    VERCEL_ENV: z.string(),
    VERCEL_URL: z.string(),
    CRON_AUTHORIZATION_HEADER: z
      .string()
      .default("8619a566-0251-4d12-8aa2-24773fcdea8b"),
    STRIPE_API_SECRET_KEY: z.string(),
    STRIPE_WEBHOOK_SECRET: z.string(),
  },
  /**
   * Specify your client-side environment variables schema here.
   * For them to be exposed to the client, prefix them with `NEXT_PUBLIC_`.
   */
  client: {
    NEXT_PUBLIC_VERCEL_ENV: z.string(),
    NEXT_PUBLIC_HOSTNAME: z.string(),
  },
  /**
   * Destructure all variables from `process.env` to make sure they aren't tree-shaken away.
   */
  runtimeEnv: {
    VERCEL_ENV: process.env.VERCEL_ENV ?? "local",
    VERCEL_URL: process.env.VERCEL_URL ?? "localhost:3003",

    NEXT_PUBLIC_VERCEL_ENV: process.env.NEXT_PUBLIC_VERCEL_ENV ?? "local",
    NEXT_PUBLIC_HOSTNAME: process.env.VERCEL_ENV ?? "princess",
    CRON_AUTHORIZATION_HEADER: process.env.CRON_AUTHORIZATION_HEADER,
    STRIPE_API_SECRET_KEY: process.env.STRIPE_API_SECRET_KEY,
    STRIPE_WEBHOOK_SECRET: process.env.STRIPE_WEBHOOK_SECRET,
  },
  skipValidation: !!process.env.CI || !!process.env.SKIP_ENV_VALIDATION,
});
