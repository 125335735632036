import { cn } from "@princess/ui";
import Link from "next/link";
import { type ComponentProps } from "react";

export const NavLink = ({
  className,
  ...props
}: ComponentProps<typeof Link>) => {
  return (
    <Link
      className={cn(
        "hover:text-primary-500 relative flex w-max items-center gap-2 overflow-visible px-3 py-2 text-base font-normal",
        className,
      )}
      {...props}
    />
  );
};
