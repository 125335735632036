import { FormButton } from "@/oldFeatures/tailwind/components/FormButton";
import { LoadingSpinner } from "@/oldFeatures/tailwind/components/LoadingSpinner";
import { type Control, useWatch } from "react-hook-form";

type Props = {
  isLoading?: boolean;
  control: Control<
    { areaCode: string; phone: string; verifyCode: string },
    any
  >;
  buttonText: string;
  error?: string;
  disabled?: boolean;
  onClick: () => Promise<void>;
};

export const SendVerificationCodeButton = ({
  isLoading,
  control,
  buttonText,
  error,
  disabled,
  onClick,
}: Props) => {
  const phone = useWatch({ control, name: "phone" });

  return (
    <FormButton
      variant="outlined"
      type="button"
      className="flex-1"
      disabled={!!error || disabled || phone === ""}
      onClick={onClick}
    >
      {isLoading && <LoadingSpinner className="text-primary" />}
      {buttonText}
    </FormButton>
  );
};
