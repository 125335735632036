import { useTranslation } from "next-i18next";
import { NextSeo, type NextSeoProps } from "next-seo";

export const SiteSeo = ({ hostname }: { hostname: string }) => {
  const isMbb = hostname === "millionbb";
  const { t } = useTranslation("home");
  const defaultTitle = isMbb ? t("title.millionbb") : t("title.princess");
  const titleTemplate = `%s | ${defaultTitle}`;
  const description = isMbb
    ? t("main.sub_desc_millionbb")
    : t("main.sub_desc_princess");

  const additionalMetaTags = [
    {
      name: "application-name",
      content: defaultTitle,
    },
    {
      name: "apple-mobile-web-app-capable",
      content: "yes",
    },
    {
      name: "apple-mobile-web-app-status-bar-style",
      content: "default",
    },
    {
      name: "apple-mobile-web-app-title",
      content: defaultTitle,
    },
    {
      name: "mobile-web-app-capable",
      content: "yes",
    },
    {
      name: "theme-color",
      content: "white",
    },
  ];

  const additionalLinkTags = [
    {
      rel: "icon",
      href: isMbb ? "/favicon_mb.ico" : "/favicon_pc.ico",
    },
    {
      rel: "shortcut icon",
      href: isMbb ? "/favicon_mb.ico" : "/favicon_pc.ico",
    },
    {
      rel: "apple-touch-icon",
      href: isMbb
        ? "/assets/images/millionbb.jpg"
        : "/assets/images/princess_dating.jpg",
      sizes: "180x180",
    },
    {
      rel: "manifest",
      href: isMbb ? "/manifest.json" : "/pcmanifest.json",
    },
  ];

  const seoProps: NextSeoProps = {
    titleTemplate,
    defaultTitle,
    description,
    additionalMetaTags,
    additionalLinkTags,
  };

  return <NextSeo {...seoProps} />;
};
