import { Button, type ButtonProps } from "../Button";
import { forwardRef } from "react";
import { cn } from "..";

type IconButtonProps = ButtonProps;

export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  (props, ref) => {
    const { children, className, ...restProps } = props;

    return (
      <Button
        ref={ref}
        variant="ghost"
        size="icon"
        className={cn("w-auto hover:bg-transparent", className)}
        {...restProps}
      >
        {children}
      </Button>
    );
  },
);
