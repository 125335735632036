import { getPublicConfig } from "@/helpers/getPublicConfig";
import { type ExtractFormDataType } from "@/modules/form-builders/FormBuilder";
import { Button } from "@princess/ui/button";
import { Text } from "@princess/ui/custom/text";

const { isProd, test } = getPublicConfig();

type TestUserButtonGroup = {
  onSubmit: (data: ExtractFormDataType<any>) => Promise<void>;
};

export const TestUserButtonGroup = ({
  onSubmit: handleLogin,
}: TestUserButtonGroup) => {
  if (isProd || !test) return null;
  const { testUsers } = test;

  return (
    <div className="grid grid-cols-2 place-content-around gap-2.5">
      {testUsers.map((user) => {
        return (
          <Button
            variant="destructive"
            key={user.userId}
            className="flex items-center justify-center self-stretch rounded-[8px] border border-gray-100 px-5 py-[9x]"
            onClick={() => handleLogin(user)}
          >
            <Text className="text-gray-500">{user.title}</Text>
          </Button>
        );
      })}
    </div>
  );
};
