import { type VariantProps, cva } from "class-variance-authority";
import {
  type ComponentPropsWithoutRef,
  type ElementType,
  type PropsWithChildren,
} from "react";
import { cn } from "..";

export const textVariant = cva("tracking-[0px]", {
  variants: {
    variant: {
      normal: "text-gray-700",
      dark: "text-gray-900",
      light: "text-gray-500",
      muted: "text-muted-foreground",
    },
    size: {
      // 18px
      lg: "text-lg leading-[1.75rem]",
      // 16px
      md: "text-base leading-[1.5rem]",
      // 14px
      sm: "text-sm leading-[1.25rem]",
      // 12px
      xs: "text-xs leading-[1rem]",
    },
    weight: {
      normal: "font-normal",
      medium: "font-medium",
    },
  },
  defaultVariants: {
    variant: "normal",
    size: "md",
    weight: "normal",
  },
});

export type TextVariants = VariantProps<typeof textVariant>;

// https://stackoverflow.com/a/73111207
const defaultElement = "span";

type PolymorphicProps<TElementType extends ElementType> = PropsWithChildren<
  ComponentPropsWithoutRef<TElementType> & { as?: TElementType }
>;

export type TextProps<TElementType extends ElementType> =
  PolymorphicProps<TElementType> & TextVariants;

export const Text = <TElementType extends ElementType = typeof defaultElement>({
  as,
  variant,
  size,
  weight,
  className,
  children,
  ...props
}: TextProps<TElementType>) => {
  const Comp = as ?? defaultElement;

  return (
    <Comp
      className={cn(textVariant({ variant, size, weight, className }))}
      {...props}
    >
      {children}
    </Comp>
  );
};
