import LanguageIcon from "public/assets/icons/language.svg";
import { appLanguages } from "@/consts/appLanguage";

import { useAppLanguage } from "@/featuresPrincess/common/hooks/useAppLanguage";
import { cn } from "@/helpers/cn";
import type { AppLanguage } from "@/types/appLanguage";
import { useMemo, useCallback } from "react";

import {
  AccordionItem,
  AccordionContent,
  AccordionTrigger,
} from "@princess/ui/accordion";
import { Button } from "@princess/ui/button";
import { Text } from "@princess/ui/custom/text";
type LanguageOptionAccordionItemProps = {
  onLanguageChange: () => void;
};
export const LanguageOptionAccordionItem = ({
  onLanguageChange,
}: LanguageOptionAccordionItemProps) => {
  const { currentLanguage, changeLanguage } = useAppLanguage();

  const chipText = useMemo(
    () => appLanguages[currentLanguage].labelShort,
    [currentLanguage],
  );

  const onLanguageClick = useCallback(
    (language: AppLanguage) => {
      onLanguageChange();
      changeLanguage(language);
    },
    [changeLanguage, onLanguageChange],
  );

  return (
    <AccordionItem value="language" className="border-b-0">
      <AccordionTrigger className="px-5 py-2">
        <div className="flex w-max items-center gap-1">
          <LanguageIcon className="h-6 w-6" />
          <Text>{chipText}</Text>
        </div>
      </AccordionTrigger>
      <AccordionContent className="ml-4 flex w-max flex-col gap-1 pb-0">
        {Object.entries(appLanguages).map(([key, value]) => (
          <Button
            key={key}
            variant="ghost"
            className={cn(
              "justify-start px-3 py-2 font-normal",
              currentLanguage === key ? "text-primary" : "text-gray-700",
            )}
            onClick={() => onLanguageClick(key as AppLanguage)}
          >
            {value.label}
          </Button>
        ))}
      </AccordionContent>
    </AccordionItem>
  );
};
