export type ProjectConfig = typeof princessProjectConfig;

const princessProjectConfig = {
  project: {
    siteName: "Princess Dating",
    projectSlug: "princess-web",
  },
  assets: {},
};

const millionbbProjectConfig: ProjectConfig = {
  project: {
    siteName: "MillionBB",
    projectSlug: "millionbb-web",
  },
  assets: {},
};

export const projectSetting: Record<string, ProjectConfig> = {
  ["princess"]: princessProjectConfig,
  ["princess-dating"]: princessProjectConfig,
  ["millionbb"]: millionbbProjectConfig,
};
