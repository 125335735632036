import { useUserContext } from "@/oldFeatures/auth/hooks";
import { useInjectedLoggers } from "@/oldFeatures/ga";
import {
  GenderEnum,
  useUserContinueWithPhoneMutation,
  useUserRegisterMutation,
  useUserVerifyMutation,
} from "@princess/graphql-codegen/graphql-hooks";
import { useTranslation } from "next-i18next";
import { useCallback, useMemo } from "react";

export const usePhoneAuth = () => {
  const { authLogger } = useInjectedLoggers();
  const [continueWithPhone] = useUserContinueWithPhoneMutation();
  // Verify verifyCode - pass in phone+verifyCode when login with phone,
  // otherwise pass in verifyCode only when register
  const [userVerify] = useUserVerifyMutation();
  const { refetchUser, setToken } = useUserContext();
  const [register] = useUserRegisterMutation();
  const { i18n } = useTranslation();

  const handlePhoneRegister = useCallback(async () => {
    // register resolver, return user with idtoken
    authLogger.register("phone");
    const { data } = await register({
      variables: {
        userRegisterInput: {
          gender: GenderEnum.M,
          lang: i18n.language,
        },
      },
    });
    if (data?.userRegister?.idToken) {
      setToken(data?.userRegister.idToken);

      await refetchUser();
    }
    // @todo referrer id
  }, [authLogger, i18n.language, refetchUser, register, setToken]);

  const handlePhoneSendVerifyCode = useCallback(
    async (payload: { phone: string; recaptchaToken: string }) => {
      // phoneLogin resolver, return boolean
      authLogger.login("phone");
      // Handle error in forms to show form error
      await continueWithPhone({
        variables: {
          input: {
            phone: payload.phone,
            recaptchaToken: payload.recaptchaToken,
          },
        },
      });
    },
    [authLogger, continueWithPhone],
  );

  const handlePhoneLoginVerify = useCallback(
    async (payload: { phone: string; verifyCode: string }) => {
      // verify resolver (with phone), return user with idtoken
      authLogger.verify("phone");
      const { data } = await userVerify({
        variables: {
          userVerifyInput: {
            ...payload,
          },
        },
      });

      if (data?.userVerify?.idToken) {
        setToken(data?.userVerify.idToken);

        // Do not setUser with the result, so as to maintain single source of truth of user object
        await refetchUser();
      }
    },
    [authLogger, refetchUser, setToken, userVerify],
  );

  return useMemo(
    () => ({
      handlePhoneSendVerifyCode,
      handlePhoneLoginVerify,
      handlePhoneRegister,
    }),
    [handlePhoneLoginVerify, handlePhoneSendVerifyCode, handlePhoneRegister],
  );
};
