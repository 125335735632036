import { getFileByObjectUrl } from "@/helpers/utils";
import { useCallback } from "react";
import * as Sentry from "@sentry/nextjs";
import { FeatureEnum } from "@/services/cloudinary/uploadImages";
import { useUploadImageCloudinary } from "@/helpers/useUploadImageCloudinary";
import { useSnackbar } from "notistack";
import { CloudinaryImageType } from "@princess/helpers/src/cloudinary";

export const useAvatarUpload = () => {
  const { uploadImageToCloudinary } = useUploadImageCloudinary();
  const { enqueueSnackbar } = useSnackbar();

  const uploadAvatar = useCallback(
    async ({ url, userId }: { url: string; userId: string }) => {
      try {
        const avatarFile = await getFileByObjectUrl(url, `${userId}_avatar`);

        const response = await uploadImageToCloudinary(
          avatarFile,
          CloudinaryImageType.AVATAR_HEAD,
          FeatureEnum.Avatar,
        );

        if (!response) return;

        return response;
      } catch (error) {
        Sentry.captureException(error);
        enqueueSnackbar((error as Error).message);
      }
    },
    [enqueueSnackbar, uploadImageToCloudinary],
  );

  return uploadAvatar;
};
