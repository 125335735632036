import { useTranslation } from "next-i18next";
import { useCallback, useMemo } from "react";
import Cookies from "js-cookie";
import { useRouter } from "next/router";
import type { AppLanguage } from "@/types/appLanguage";

export const useAppLanguage = () => {
  const { i18n } = useTranslation("home");
  const router = useRouter();

  const currentLanguage = useMemo(
    () => (i18n.language as AppLanguage | undefined) ?? "zh",
    [i18n],
  );

  const changeLanguage = useCallback(
    (language: AppLanguage) => {
      Cookies.set("NEXT_LOCALE", language);

      i18n.changeLanguage(language);

      router.push(router.asPath, router.asPath, { locale: language });
    },
    [i18n, router],
  );

  return useMemo(
    () => ({
      currentLanguage,
      changeLanguage,
    }),
    [changeLanguage, currentLanguage],
  );
};
