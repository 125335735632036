import { getPublicConfig } from "@/helpers/getPublicConfig";
import { Cloudinary } from "@cloudinary/url-gen";

const {
  service: {
    cloudinary: { cloudName },
  },
} = getPublicConfig();

export const cld = new Cloudinary({
  cloud: {
    cloudName,
  },
  url: {
    secure: true,
  },
});
