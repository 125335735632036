import { getPublicConfig } from "./getPublicConfig";
import imageCompression from "browser-image-compression";

const {
  features: {
    image: { compression },
  },
} = getPublicConfig();

// In order to make sure the base64 wont exceed 1MB (due to some unknown server error),
// We need to calculate the max possible file size.
// Ref: https://en.wikipedia.org/wiki/Base64#MIME
const MAX_SIZE_MB = (1048576 - 814) / 1.37 / 1048576;

export const compressImageBase64 = async (file: File) => {
  const compressedFile = await imageCompression(file, {
    maxSizeMB: MAX_SIZE_MB,
  });
  const base64 = await imageCompression.getDataUrlFromFile(compressedFile);
  return { compressedFile, base64 };
};

export const compressImage = async (file: File) => {
  return imageCompression(file, {
    maxSizeMB: compression.maxSizeMB,
    maxWidthOrHeight: compression.maxHeight,
  });
};
