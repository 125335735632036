import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "@princess/ui/dropdownMenu";
import { UserMenuContent } from "@/featuresPrincess/layout/userMenu/UserMenuContent";
import { type UserMenuItem } from "@/featuresPrincess/layout/types";
import { useUserContext } from "@/oldFeatures/auth/hooks";
import UserMenuIcon from "public/assets/icons/user_menu.svg";
import { useCallback, useState } from "react";
import { cn } from "@/helpers/cn";
import { AvatarDisplay } from "@/features/user/components/AvatarDisplay";

export const UserDropdownMenu = ({
  menuItems,
}: {
  menuItems: UserMenuItem[];
}) => {
  const { user } = useUserContext();
  const [isOpen, setIsOpen] = useState(false);

  const closeMenu = useCallback(() => {
    setIsOpen(false);
  }, []);

  if (!user) return null;

  return (
    <DropdownMenu open={isOpen} onOpenChange={setIsOpen} modal={false}>
      <DropdownMenuTrigger>
        <div className="relative">
          <AvatarDisplay
            user={user}
            alt={user.nickname ?? user.userId}
            shape="roundedFull"
            size="2xs"
            className={cn(
              "border-2",
              isOpen ? "border-primary-500" : "border-transparent",
            )}
          />
          <UserMenuIcon className="absolute bottom-0 right-0 h-6 w-6" />
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        className="rounded-[12px] p-0"
        align="end"
        hideWhenDetached
      >
        <UserMenuContent menuItems={menuItems} closeMenu={closeMenu} />
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
