import type { ComponentType, ReactNode } from "react";

type UserDropdownMenuNavigationItemProps = {
  Icon: ComponentType<any>;
  title: string;
  subtitle?: string | ReactNode;
};
export const UserDropdownMenuNavigationItem = ({
  Icon,
  title,
  subtitle,
}: UserDropdownMenuNavigationItemProps) => {
  return (
    <div className="flex w-full items-center gap-3 text-base text-gray-900">
      <Icon className="h-6 w-6" />
      <span className="text-base font-normal text-gray-900">{title}</span>

      {subtitle && (
        <div className="ml-auto text-base font-normal text-gray-400">
          {subtitle}
        </div>
      )}
    </div>
  );
};
