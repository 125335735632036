import "firebase/analytics";
import { getPublicConfig } from "@/helpers/getPublicConfig";
import firebase from "firebase/app";

const config = getPublicConfig();

export function initFirebase() {
  if (typeof window !== "undefined" && !firebase.apps.length) {
    firebase.initializeApp(config.service.firebase);
    if ("measurementId" in config.service.firebase) {
      firebase.analytics();
    }
  }
}
