import { cn } from ".";

/**
 * this component is from shadcn : https://ui.shadcn.com/docs/components/skeleton
 */
function Skeleton({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      className={cn("bg-muted animate-pulse rounded-md", className)}
      {...props}
    />
  );
}

export { Skeleton };
